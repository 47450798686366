<template>
	<div class="portal-wrapper-content portal-wrapper-content-application">
		<div class="row">
			<!-- <portal-loader></portal-loader> -->
			<portal-navigation></portal-navigation>
			<portal-view></portal-view>
		</div>
	</div>
</template>

<script>
	import PortalLoader from './PortalLoader.vue';
	import PortalNavigation from './PortalNavigation.vue';
	import PortalView from './PortalView.vue';

	export default {
		name: 'portal-wrapper',
		components: {
			PortalLoader,
			PortalNavigation,
			PortalView
		}
	}
</script>
