<template>
	<div class="col-md-6 padding-left-10">
		<div class="box">

			<template v-if="loadingStates.user">
				<portal-loader-circle></portal-loader-circle>
			</template>
			<template v-else>
				<div class="card" v-if="edit == false">
					<div class="row">
						<div class="col-md-6">
							<div class="box-item">
								<strong class="box-item-title">{{ $t('label.weight') }}</strong>
								<p class="box-item-text">
									{{ user.weight }} {{ $t('label.kilogram') }}
								</p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="box-item">
								<strong class="box-item-title">{{ $t('label.height') }}</strong>
								<p class="box-item-text">
									{{ user.height }} {{ $t('label.centimeter') }}
								</p>
							</div>
						</div>
						<div class="col-md-12">
							<div class="box-item">
								<strong class="box-item-title">{{ $t('label.originother') }}</strong>
								<p class="box-item-text">
									{{ otherContinent }}
								</p>
							</div>
						</div>
					</div>
					<a href="#" class="edit fa fa-pencil" v-on:click.prevent="toggleEdit"><span class="hidden">Bewerken</span></a>
				</div>

				<Form class="form validate" v-if="edit == true">
					<div class="row">
						<div class="col-md-6">
							<div class="box-item">
								<div class="form-group" v-bind:class="{'validation-error': hasError('weight')}">
									<label for="weight">{{ $t('label.weight') }} <span class="meta">Kg</span></label>
									<Field autocomplete="no" type="number" class="form-control required digits" name="weight" id="gewicht" v-model="formData.weight" />
									<span class="error" v-show="errors.weight">{{ $t('error.maxweight') }}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="box-item">
								<div class="form-group" v-bind:class="{'validation-error': hasError('height')}">
									<label for="height">{{ $t('label.height') }} <span class="meta">Cm</span></label>
									<Field autocomplete="no" type="number" class="form-control required digits" name="height" id="lengte" v-model="formData.height" />
									<span class="error" v-show="errors.height">{{ $t('error.maxheight') }}</span>
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="box-item">
								<div class="form-group">
									<p class="box-item-text box-item-text-label">
										{{ $t('label.originother') }}
									</p>
									<div class="row">
										<div class="form-group radio-group col-md-4">
											<Field type="radio" class="form-control" name="orginother-yes" id="orginother-yes" value="true" v-model="formData.otherContinent"/>
											<label for="orginother-yes" v-html="$t('radio.yes')"></label>
										</div>
										<div class="form-group radio-group col-md-8">
											<Field type="radio" class="form-control" name="orginother-no" id="orginother-no" value="false" v-model="formData.otherContinent"/>
											<label for="orginother-no" v-html="$t('radio.no')"></label>
										</div>
										<div class="form-group radio-group col-md-12">
											<Field type="radio" class="form-control" name="orginother-unknown" id="orginother-unknown" value="unknown" v-model="formData.otherContinent"/>
											<label for="orginother-unknown" v-html="$t('radio.notwilling')"></label>
										</div>
									</div>
								</div>
							</div>
						</div>
                        <div v-show="formData.otherContinent == 'true'">
                            <div class="col-md-6">
                                <div class="box-item">
                                    <div class="form-group" v-bind:class="{'validation-error': hasError('originFather')}">
                                        <label for="originFather">
                                            <span class="placeholder" :class="{'hidden': originFatherNotDefault}">{{ $t('label.originFather') }}</span>
                                        </label>
                                        <Field as="select" autocomplete="off" name="originFather" id="originFather" class="form-control select-month required" v-model="formData.originFather" v-validate="requiredOriginFatherIfOtherContinent" :deze="test" data-vv-validate-on="none">
                                            <option value="mixed">{{ $t('placeholder.mixedOrigin') }}</option>
                                            <option v-for="country in sortedCountries" :value="country.slug">{{ country.name }}</option>
                                        </Field>
                                        <span class="error" v-show="errors.originFather">{{ $t('error.required') }}</span>
                                    </div>
                                    <div class="form-group" :class="{'error': errors.originFatherMixed}" v-show="formData.originFather == 'mixed'">
                                            <label for="originFatherMixed" class="hidden">{{ $t('label.mixedOrigin') }}</label>
                                            <Field class="form-control required" name="originFatherMixed" id="originFatherMixed" v-model="formData.originFatherMixed" v-validate="requiredIfOriginFatherMixed" :placeholder="$t('label.mixedOrigin')"/>
                                            <span class="error" v-show="errors.originFatherMixed">{{ $t('error.required') }}</span>
                                        </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="box-item">
                                    <div class="form-group" v-bind:class="{'validation-error': hasError('height')}">
                                        <label for="originMother">
                                            <span class="placeholder" :class="{'hidden': originMotherNotDefault}">{{ $t('label.originMother') }}</span>
                                        </label>
                                        <Field as="select" autocomplete="off" name="originMother" id="originMother" class="form-control select-month required" v-model="formData.originMother">
                                            <option value="mixed">{{ $t('placeholder.mixedOrigin') }}</option>
                                            <option v-for="country in sortedCountries" :value="country.slug">{{ country.name }}</option>
                                        </Field>
                                        <span class="error" v-show="errors.originMother">{{ $t('error.required') }}</span>
                                    </div>
                                    <div class="form-group" :class="{'error': errors.originMotherMixed}" v-show="formData.originMother == 'mixed'">
                                        <label for="originMotherMixed" class="hidden">{{ $t('label.mixedOrigin') }}</label>
                                        <Field class="form-control required" name="originMotherMixed" id="originMotherMixed" v-model="formData.originMotherMixed" :placeholder="$t('label.mixedOrigin')"/>
                                        <span class="error" v-show="errors.originMotherMixed">{{ $t('error.required') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div class="col-md-12">
							<div class="box-item">
								<div class="form-group footer-group clearfix">
									<button type="submit" class="btn button orange submit ld-ext-right" v-on:click.prevent="save" v-bind:class="{running: loading}">
										<span>{{ $t('button.save') }}</span>
										<div class="ld ld-ring ld-spin"></div>
									</button>
									<button class="btn button blue fa fa-times" v-on:click.prevent="toggleEdit"></button>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</template>

		</div>
	</div>
</template>
<script>
	import Vue from 'vue';
	import {mapState} from 'vuex';
    import _ from 'lodash';
    import CountriesNL from './../../../data/countries_nl.json';
    import CountriesEN from './../../../data/countries_en.json';
	import PortalLoaderCircle from './../layout/PortalLoaderCircle.vue';
    import * as types from '../../../store/mutation-types';

	export default {
        name: 'portal-personaldetails-editpersonal',
        components: {
            PortalLoaderCircle
        },
		data: function(){
            return {
                edit: false,
                loading: false,
                countries: CountriesNL, 
				formData: {},
				backendErrors: []
			}
        },
		computed: {
    	...mapState ('user', [
      	'user'
			]),
    	...mapState ('app', [
          'loadingStates',
          'locale'
			]),
			otherContinent() {
				switch (this.user.otherContinent) {
					case 'true':
					return this.$i18n.t('radio.yes');
					break;
					case 'false':
					return this.$i18n.t('radio.no');
					break;
					case 'unknown':
					return this.$i18n.t('radio.notwilling');
					break;
					default:
					return '-';
					break;
				}
            },
            sortedCountries: function() {
                function compare(a, b) {

                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                }

                return this.countries.sort(compare);
            },
            originFatherNotDefault() {
                return ((this.formData.originFather && this.formData.originFather != this.$i18n.t('placeholder.originFather')) ? true : false);
            },
            originMotherNotDefault() {
                return ((this.formData.originMother && this.formData.originMother != this.$i18n.t('placeholder.originMother')) ? true : false);
            },
            requiredOriginFatherIfOtherContinent() {
                return this.formData.otherContinent == 'true' ? {required: true, nodefault: this.$i18n.t('placeholder.originFather')} : '';
            },
            requiredOriginMotherIfOtherContinent() {
                return this.formData.otherContinent == 'true' ? {required: true, nodefault: this.$i18n.t('placeholder.originMother')} : '';
            },
            requiredIfOriginMotherMixed() {
                return (this.formData.originMother == 'mixed') ? 'required' : '';
            },
            requiredIfOriginFatherMixed() {
                return (this.formData.originFather == 'mixed') ? 'required' : '';
            },
		},
		methods: {
			hasError(field) {
                var backendErrorFields = this.backendErrors.map(error => error.field);
				if (this.errors.field || (backendErrorFields.indexOf(field) > -1)) {
					return true;
				}
				return false;
			},
			toggleEdit: function(event) {
				this.edit = !this.edit;
				if (this.edit) {
					this.formData = _.cloneDeep(this.$store.state.user.user)
				}
            },
            changeCountryList(locale) {
                switch (locale) {
                    case 'en':
                        this.countries = CountriesEN;
                        break;
                    default:
                        this.countries = CountriesNL;
                        break;
                }
            },
            save: function(event) {
                if (!this.loading) {
					this.$validator.validateAll().then((result) => {
						if (result) {
							this.loading = true;
							this.$store.dispatch('user/update', {
								weight: this.formData.weight,
								height: this.formData.height,
								medication: this.formData.medication,
                                otherContinent: this.formData.otherContinent,
                                originMother: this.formData.originMother,
                                originFather: this.formData.originFather,
                                originMotherMixed: this.formData.originMotherMixed,
                                originFatherMixed: this.formData.originFatherMixed,
								type: 'medical'
							}).then((response) => {
								this.loading = false;
								this.backendErrors = [];
								this.edit = false;
                                this.$store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('alert.success'), type: "alert-success"});
                                setTimeout(()=>{
                                    this.$store.commit('app/'+types.RESET_NOTICE);
                                }, 2500);
							}).catch((error) => {
								this.loading = false;
								this.backendErrors = error.errors;
							});
						}
					});
                }
            }
        },
        
	}
</script>
