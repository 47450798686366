<template>
	<div class="col-md-12">
		<div class="loader">
			<div class="spinner">
			  <div class="bounce1"></div>
			  <div class="bounce2"></div>
			  <div class="bounce3"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'portal-loader'
	}
</script>
