<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
					<div class="portal-wrapper">
						<div class="portal-wrapper-header portal-wrapper-header-login">
							<h2 class="title">{{ $t('verify.title') }}</h2>
							<div class="lead">
								<p>{{ $t('verify.intro') }}</p>
							</div>
						</div>
						<div class="portal-wrapper-content portal-wrapper-content-login">
							<Form @submit="advance" :validation-schema="schema" v-slot="{ errors }" class="form-wrapper form validate validate-address">
								<div class="form-wrapper-top">
									<div class="row">
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-12">
													<h3 class="heading">{{ $t('verify.description') }}</h3>
												</div>
												<div class="col-md-4 padding-right-10">
													<div class="box-item">
														<div class="form-group label-on-top" v-bind:class="{'validation-error': errors.zipcodeNumerals, 'success': zipcodeNumeralsIsValid}">
															<label for="zipcodeNumerals">{{ $t('label.zipcode') }} <span class="hidden">cijfers</span></label>
															<Field autocomplete="off" min="1" max="9999" type="number" class="form-control required number" data-rule-minlength="4" name="zipcodeNumerals" @change="fetchAddress" id="postcode_cijfers" v-model="formData.zipcodeNumerals" data-vv-delay="1000" />
														</div>
													</div>
												</div>
												<div class="col-md-4 padding-left-10 padding-right-10">
													<div class="box-item">
														<div class="form-group label-on-top" v-bind:class="{'validation-error': errors.zipcodeLetters, 'success': zipcodeLettersIsValid}">
															<label for="zipcodeLetters">&nbsp;<span class="hidden">Postcode letters</span></label>
															<Field autocomplete="off" minlength="2" maxlength="2" type="text" class="form-control required" data-rule-minlength="2" name="zipcodeLetters" @change="fetchAddress" id="postcode_letters" v-model="formData.zipcodeLetters" data-vv-delay="1000"/>
														</div>
													</div>
												</div>
												<div class="col-md-4 padding-left-10">
													<div class="box-item">
														<div class="form-group label-on-top" v-bind:class="{'validation-error': errors.streetNumber, 'success': streetNumberIsValid}">
															<label for="streetNumber">{{ $t('label.streetnumber') }}</label>
															<Field autocomplete="off" type="text" class="form-control required" name="streetNumber" id="huisnummer" v-model="formData.streetNumber" @change="fetchAddress" data-vv-delay="1000" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
                                                <div class="col-md-6 padding-right-10">
                                                    <div class="box-item">
                                                        <div class="form-group label-on-top" v-bind:class="{'validation-error': errors.street, 'success': streetIsValid}">
                                                            <label for="street">{{ $t('label.streetname') }}</label>
                                                            <Field autocomplete="off" :disabled="isDisabled ? true : null" type="text" class="form-control required" name="street" ref="street" v-bind:class="{'is-disabled': isDisabled, 'is-loading': loading}" id="straatnaam" v-model="formData.street" data-vv-delay="1000" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 padding-left-10">
                                                    <div class="box-item">
                                                        <div class="form-group label-on-top" v-bind:class="{'validation-error': hasError('city'), 'success': citysIsValid}">
                                                            <label for="city">{{ $t('label.city') }}</label>
                                                            <Field autocomplete="off" :disabled="isDisabled ? true : null" type="text" class="form-control required" v-bind:class="{'is-disabled': isDisabled, 'is-loading': loading}" name="city" id="plaats" v-model="formData.city" data-vv-delay="1000" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12" v-show="isDisabled">
                                                    <a href="#" v-on:click.prevent="forceAddressValidated">{{ $t('link.correctAddress') }}</a>
                                                </div>
										    </div>                                 
										</div>
									</div>
								</div>
								<div class="centered overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
									{{ errorMessage }}
								</div>
								<div class="form-wrapper-bottom">
									<div class="row">
										<div class="form-group col-md-12 centered">
											<button v-on:click.prevent="advance" type="submit" class="btn button orange" v-bind:class="{loading: loading}">
												<span class="text">{{ $t('button.proceed') }}</span>
												<div class="loader">
													<span></span>
													<span></span>
													<span></span>
												</div>
											</button>
										</div>
									</div>
								</div>
							</Form>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import * as types from '../store/mutation-types';
	import _ from 'lodash';
    import * as yup from 'yup';
    import { Form, Field } from 'vee-validate';

  export default {
		name: 'verify-address',
		data: function() {
			return {
				loading: false,
				errorMessage: false,
				formData: {},
                errors: '',
                backendErrors: [],
                addressValidated: false,
			}
        },
        components: {
            Form,
            Field,
        },
        setup() {
            const schema = yup.object({
                zipcodeNumerals: yup.number().required().max(9999),
                zipcodeLetters: yup.string().required().max(2),
                streetNumber: yup.string().required(),
                street: yup.string().required(),
                city: yup.string().required(),
            });
            return {
                schema
            };
        },
        created () {
            // redirect to verify mobile or email when one of the two is not available
            this.formData = _.cloneDeep(this.$store.state.user.user);
            this.fetchAddress();
        },
        methods: {
            fetchAddress() {
                if (this.address) {
                    this.loading = true;
                    this.$store.dispatch('user/fetchAddress', this.address).then((response) => {
                        this.formData.street = response.street;
                        this.formData.city = response.city;
                        this.loading = false;
                    }).catch((error) => {
                        this.formData.street = '';
                        this.formData.city = '';
                        this.loading = false;
                    });
                }
            },
            forceAddressValidated() {
                this.addressValidated = true;
                //this.$refs.street.focus();
            },
			hasError(field) {
                var backendErrorFields = this.backendErrors.map(error => error.field);
				if (this.errors[field]|| (backendErrorFields.indexOf(field) > -1)) {
					return true;
				}
				return false;
			},
			advance: function(event) {
                this.loading = true;
                this.$store.dispatch('user/update', {
                    'street': this.formData.street,
                    'streetNumber': this.formData.streetNumber,
                    'zipcodeNumerals': this.formData.zipcodeNumerals,
                    'zipcodeLetters': this.formData.zipcodeLetters,
                    'city': this.formData.city,
                    'type': 'general'
                }).then((response) => {
                    this.loading = false;
                    this.$store.commit('auth/'+types.SET_FIRST_LOGIN, false);
                    if (this.resetLastName) {
                        this.$router.push({name: 'verify-lastname'});
                    } else{
                        this.$router.push({name: 'portal-overview'});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.backendErrors = error.errors;
                });
			}
        },
		computed: {
            ...mapState ('user', [
				'user'
            ]),
            ...mapState ('auth', [
                'resetLastName',
            ]),
			streetIsValid() {
                return (this.formData.street.length > 0 && !this.hasError('street')) ? true : false;
			},
			streetNumberIsValid() {
                return (this.formData.streetNumber.length > 0 && !this.hasError('streetNumber')) ? true : false;
			},
			zipcodeNumeralsIsValid() {
                return (this.formData.zipcodeNumerals.length > 0 && !this.hasError('zipcodeNumerals')) ? true : false;
			},
			zipcodeLettersIsValid() {
                return (this.formData.zipcodeLetters.length > 0 && !this.hasError('zipcodeLetters')) ? true : false;
			},
			cityIsValid() {
                return (this.formData.city.length > 0 && !this.hasError('city')) ? true : false;
            },
            address() {
                return this.formData.zipcodeNumerals + this.formData.zipcodeLetters.toUpperCase() + '+' + this.formData.streetNumber;
            },
            isDisabled() {
                return !this.addressValidated;
            }
		},
        
	}
</script>
