<template>
	<div class="portal-wrapper-header portal-wrapper-header-application">
		<h2 class="account"><span v-if="loadingStates.user"><portal-loader-circle></portal-loader-circle></span><span v-else>{{ fullName }}</span></h2>
		<ul class="nav clearfix">
			<li>
				<a href="#" class="btn button button-trans" v-on:click="logout" v-bind:class="{loading: loading}">
					<div class="text">
						<span class="button-text">{{ $t('button.logout') }}</span>
						<span class="fa fa-power-off"></span>
					</div>
					<div class="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import PortalLoaderCircle from './PortalLoaderCircle.vue';

	export default {
		name: 'portal-header',
		computed: {
      ...mapState ('user', [
        'user'
			]),
    	...mapState ('app', [
      	'loadingStates'
			]),
			fullName: function() {
				return this.user.firstName + ' ' + this.user.lastName;
			}
		},
		data: function(){
			return {
				loading: false
			}
		},
		methods: {
      logout: function(event) {
        if (!this.loading) {
					this.loading = true;
          this.$store.dispatch('auth/logout').then((response) => {
						this.loading = false;
						// dispatch new init request after logout -> get new csrf token
						// this.$store.dispatch('app/init');						
						// this.$router.push({name: 'login'});
						// reinit app by reloading page and resetting application localsession store cache
						localStorage.clear();
						window.location.href = '/';
          }).catch((error) => {
						this.loading = false;
					});
        }
      }
		},
		components: {
			PortalLoaderCircle
		}
	}
</script>
