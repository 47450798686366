<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
					<div class="portal-wrapper">

						<div class="portal-wrapper-header portal-wrapper-header-login">
							<h2 class="title">{{ $t('verifytoken.title') }}</h2>
							<div class="lead">
								<p>{{ $t('verifytoken.introsent1') }} <a v-on:click="sendTokenSms">{{ sendSmsTokenMessage }}</a><span v-html="$t('verifytoken.introsent2')"></span></p>
							</div>
						</div>

						<div class="portal-wrapper-content portal-wrapper-content-login">
							<Form class="form-wrapper form validate">
								<div class="form-wrapper-top">
									<div class="row">
										<div class="form-group number-group col-md-12 centered  mobile-no-padding">
											<label for="verificatie_cijfer_1" class="hidden">Verificatie cijfer 1</label>
											<Field autocomplete="off" maxlength="1" type="number" pattern="[0-9]*" inputmode="numeric" name="verificatie_cijfer_1" id="verificatie_cijfer_1" class="form-control required number" v-model="tokenChars[0]" v-on:keyup="nextPrevInput($event, 0)" v-on:keypress="onKeypress($event, 0)" @click="onClick($event, 0)" @paste="onPaste"/>
											<label for="verificatie_cijfer_2" class="hidden">Verificatie cijfer 2</label>
											<Field autocomplete="off" maxlength="1" type="number" pattern="[0-9]*" inputmode="numeric" name="verificatie_cijfer_2" id="verificatie_cijfer_2" class="form-control required number" v-model="tokenChars[1]" v-on:keyup="nextPrevInput($event, 1)" v-on:keypress="onKeypress($event, 1)" @click="onClick($event, 1)" @paste="onPaste"/>
											<label for="verificatie_cijfer_3" class="hidden">Verificatie cijfer 3</label>
											<Field autocomplete="off" maxlength="1" type="number" pattern="[0-9]*" inputmode="numeric" name="verificatie_cijfer_3" id="verificatie_cijfer_3" class="form-control required number" v-model="tokenChars[2]" v-on:keyup="nextPrevInput($event, 2)" v-on:keypress="onKeypress($event, 2)" @click="onClick($event, 2)" @paste="onPaste"/>
											<label for="verificatie_cijfer_4" class="hidden">Verificatie cijfer 4</label>
											<Field autocomplete="off" maxlength="1" type="number" pattern="[0-9]*" inputmode="numeric" name="verificatie_cijfer_4" id="verificatie_cijfer_4" class="form-control required number" v-model="tokenChars[3]" v-on:keyup="nextPrevInput($event, 3)" v-on:keypress="onKeypress($event, 3)" @click="onClick($event, 3)" @paste="onPaste"/>
											<label for="verificatie_cijfer_5" class="hidden">Verificatie cijfer 5</label>
											<Field autocomplete="off" maxlength="1" type="number" pattern="[0-9]*" inputmode="numeric" name="verificatie_cijfer_5" id="verificatie_cijfer_5" class="form-control required number" v-model="tokenChars[4]" v-on:keyup="nextPrevInput($event, 4)" v-on:keypress="onKeypress($event, 4)" @click="onClick($event, 4)" @paste="onPaste"/>
											<label for="verificatie_cijfer_6" class="hidden">Verificatie cijfer 6</label>
											<Field autocomplete="off" maxlength="1" type="number" pattern="[0-9]*" inputmode="numeric" name="verificatie_cijfer_6" id="verificatie_cijfer_6" class="form-control required number" v-model="tokenChars[5]" v-on:keyup="nextPrevInput($event, 5)" v-on:keypress="onKeypress($event, 5)" @click="onClick($event, 5)" @paste="onPaste"/>
										</div>
									</div>
								</div>
								<div class="centered overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
									{{ errorMessage }}
								</div>
								<div class="form-wrapper-bottom">
									<div class="row">
										<div class="form-group col-md-12 centered">
											<button v-on:click.prevent="authenticate" type="submit" class="btn button orange" v-bind:class="{loading: loading}">
												<span class="text">{{ $t('button.login') }}</span>
												<div class="loader">
													<span></span>
													<span></span>
													<span></span>
												</div>
											</button>
										</div>
									</div>
								</div>
							</Form>
						</div>

						<div class="portal-wrapper-footer">
                            <a v-on:click="resendToken">
                                <span>{{ resendMessage }}</span>
                            </a>   
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {mapState} from 'vuex';
    import * as yup from 'yup';
    import { Form, Field } from 'vee-validate';

export default {
    name: 'login-authenticate',
    props: ['driver'],
    data: function() {
        return {
            sendLogin: false,
            loading: false,
            resendAttempts: 1,
            maxResendAttempts: 5,
            errorMessage: '',
            resendMessage: this.$i18n.t('verifytoken.link'),
            sendSmsTokenMessage: this.$i18n.t('verifytoken.smscode'),
            tokenChars: ['', '', '', '', '', '']
        }
    },
    components: {
      Form,
      Field,
    },
    computed: {
    ...mapState ('auth', [
            'isAuthenticated',
            'availableTypes',
            'resetContact',
            'resetLastname',
            'tokenSent',
            'type'
        ]),
    ...mapState ('app', [
            'locale'
        ]),
        token() {
            return this.tokenChars.join('');
        }
    },
    watch: {
        locale: function(locale) {
            this.resendMessage = this.$i18n.t('verifytoken.link');
            // hacking mother fucker shit.. this together
        }
    },
    created() {
        if (this.resendMessage === 'verifytoken.link') {
            localStorage.clear();
            this.$router.push({name: 'login'});
        } else {
            if (!this.sendLogin) {
                this.sendLogin = true;
                this.login();
            }
        }
    },
    methods: {
        login: function(event) {
            if (!this.loading) {
                this.loading = true;
                
                this.$store.dispatch('auth/login', {type: this.driver}).then((response) => {
                    this.loading = false;
                }).catch((error) => {
                    if (error.errors) {
                        this.loading = false;
                    }
                });
            }
        },
        authenticate: function(event) {
            if (!this.loading && this.token) {
                this.errorMessage = '';
                this.loading = true;
                this.$store.dispatch('auth/authenticate', {
                    token: this.token
                }).then((response) => {
                    this.loading = false;
                    // redirect
                    if (this.isAuthenticated === true) {
                        this.$store.dispatch('user/fetch').then((response) => {
                            this.loading = false;
                            // redirect to verify mobile or email when one of the two is not available
                            if (!this.availableTypes.email || !this.availableTypes.mobile) {
                                this.$router.push({name: 'verify-contact'})
                            } else if(this.resetContact) {
                                this.$router.push({name: 'verify-address'})
                            } else if (this.resetLastname) {
                                this.$router.push({name: 'verify-address'})
                            } else {
                                this.$router.push({name: 'portal-overview'})
                            }
                        });
                    }
                }).catch((error) => {
                    this.loading = false;
                    if (error.message == 'Invalid token') {
                        this.errorMessage = this.$i18n.t('error.invalidtoken');
                    } else if (error.message == 'Resource locked') {
                        this.errorMessage = this.$i18n.t('error.tokenblock');
                    } else if (error.message == 'Token is expired') {
                        this.errorMessage = this.$i18n.t('error.expiredtoken');
                    } else {
                        this.errorMessage = this.$i18n.t('error.wrong');
                    }
                });
            } else if(!this.loading && !this.token) {
                // frontend validation
                this.errorMessage = this.$i18n.t('error.incorrect');
            }
        },
		resendToken: function(event) {
            event.preventDefault();
        	if (!this.loading && this.resendAttempts < this.maxResendAttempts) {
				this.errorMessage = '';
				this.loading = true;
        		this.$store.dispatch('auth/login', {type: 'mail'}).then((response) => {
					this.loading = false;
					this.resendAttempts++;
					// notify user, and reset message after 2 secs
					if (this.tokenSent === true) {
						var _resendMessage = this.resendMessage;
						this.resendMessage = this.$i18n.t('verifytoken.sent');
						setTimeout(() => {
							if (this.resendAttempts == this.maxResendAttempts) {
								this.resendMessage = this.$i18n.t('verifytoken.block');
							} else {
								this.resendMessage = _resendMessage;
							}
						}, 2000);
					}
         		}).catch((error) => {
					this.loading = false;
				});
        	}
		},
        sendTokenSms: function(event) {
            event.preventDefault();
        	if (!this.loading && this.resendAttempts < this.maxResendAttempts) {
				this.errorMessage = '';
				this.loading = true;
        		this.$store.dispatch('auth/login', {type: 'sms'}).then((response) => {
					this.loading = false;
					this.resendAttempts++;
					// notify user, and reset message after 2 secs
					if (this.tokenSent === true) {
						var _sendSmsTokenMessage = this.sendSmsTokenMessage;
						this.sendSmsTokenMessage = this.$i18n.t('verifytoken.sendsms');
						setTimeout(() => {
							if (this.resendAttempts == this.maxResendAttempts) {
								this.sendSmsTokenMessage = this.$i18n.t('verifytoken.blocksms');
							} else {
								this.sendSmsTokenMessage = _sendSmsTokenMessage;
							}
						}, 2000);
					}
         		}).catch((error) => {
					this.loading = false;
				});
        	}
        },
		onPaste: function(event) {
			event.preventDefault();
			let pastedValue = event.clipboardData.getData('text');
			let intRegex = /^\d+$/;

			// 123456
			if (pastedValue.length == 6 && intRegex.test(pastedValue)) {
				let pasteValueArray = pastedValue.split('');
				for (let index = 0; index < this.tokenChars.length; index++) {
					this.tokenChars[index] =  pasteValueArray[index]
				}
			}
		},
		onClick: function(event, model) {
			// clear target value and model onclick
			this.tokenChars[model] = "";
			event.target.value = "";
		},
		nextPrevInput: function(event, model) {
			// numbers between 0 and 9
			if ('key' in event && event.key >= 0 && event.key <= 9) {
				this.nextInput(event.target);
			} else if (event.keyCode >= 48 && event.keyCode <= 57) {
				this.nextInput(event.target);
			} else if ((event.target.value.charAt(event.target.value.length - 1).charCodeAt()) >= 48 && (event.target.value.charAt(event.target.value.length - 1).charCodeAt() <= 57)) {
				// bugfix on older android and chrome browsers
				this.nextInput(event.target);
			} else if ((event.key == 13)) {
                this.authenticate();
            }
			// backspace button = 8
			if (event.keyCode == 8) {
				this.prevInput(event.target, model)
			}				
		},
		onKeypress: function(event, model) {
			// if target is filled overwrite
			if (event.target.value.length && event.key != "Enter") {
				this.tokenChars[model] = "";
				event.target.value = "";					
			}
		},
		nextInput: function(next) {
			while (next = next.nextElementSibling) {
				if (next.tagName.toLowerCase() === "input") {
					next.focus();
					break;
				}
			}
		},	
		prevInput: function (previous, model) {
			while (previous = previous.previousElementSibling) {
				if (previous.tagName.toLowerCase() === "input") {
					previous.value = "";
					this.tokenChars[model-1] = "";
					previous.focus();
					break;
				}
			}
		}
	}
}
</script>
