import Vue from 'vue';
import axios from 'axios';
import store from '../store';
import router from '../router';
import * as types from '../store/mutation-types';

export const HTTP = axios.create({
    baseURL: (process.env.VUE_APP_API_URL),
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    timeout: 15000
});

// add interceptors
// updated at for disable notice when logged out after a long time
HTTP.interceptors.request.use(function (config) {
    const userLang = localStorage.getItem('selectedLang') || navigator.language || navigator.userLanguage;
    store.commit('app/'+types.UPDATE_UPDATEDAT);
    config.headers['Language'] = (userLang.substring(0, 2) == 'en') ? 'en' : 'nl';

    return config;
}, function (error) {
	return Promise.reject(error);
});

HTTP.interceptors.response.use(function (response) {
	// success
	return response;
}, function(error) {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		if (error.response.status === 500 || error.response.status === 401 || error.response.status === 423 || error.response.status === 419) {
			// ignore 401 errors on authenticating..
			if (error.response.data.message != 'Invalid token' && error.response.data.message != 'Token is expired' && error.response.data.message != 'iDIN service error') {
				store.commit('app/'+types.RESET_APP);
				store.commit('user/'+types.RESET_USER);
				store.commit('auth/'+types.RESET_AUTH);
			}
		}

		// set notice on error
		switch (error.response.status) {
			case 500:
				if (error.response.data.message != 'iDIN service error') {
					store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('error.servererror'), type: "alert-info"});
				}
				break;
			case 423:
				store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('error.ratelimitreached'), type: "alert-info"});
				break;
			case 401:
				if (error.response.data.message != 'Invalid token' && error.response.data.message != 'Token is expired') {
					store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('error.sessionexpired'), type: "alert-info"});
					router.replace('/inloggen');
				}
				break;
			case 419:
				store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('error.sessionexpired'), type: "alert-info"});
				router.replace('/inloggen');
				break;
			case 422:
				// validation errors on backend nothing wrong with this status
				// so lets catch it here to let the app function normally, in special cases generate error here
				break;
			default:
				store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('error.apierror'), type: "alert-info"});
				break;
		}
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		store.commit('app/'+types.RESET_APP);
		store.commit('user/'+types.RESET_USER);
		store.commit('auth/'+types.RESET_AUTH);
		store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('error.maintenance'), type: "alert-info"});
		router.replace('/inloggen');
	} else {
		// Something happened in setting up the request that triggered an Error
	}
	return Promise.reject(error);
});
