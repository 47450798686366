<template>
  <div class="col-md-12">
    <div class="box">
      <template v-if="loadingStates.user">
        <portal-loader-circle></portal-loader-circle>
      </template>
      <template v-else>
        <div class="card" v-if="edit == false">
          <div class="row">
            <div :class="overviewClass">
              <div class="box-item">
                <strong class="box-item-title">{{ $t("label.name") }}</strong>
                <p class="box-item-text">{{ fullName }}</p>
              </div>
            </div>
            <div :class="overviewClass" v-if="user.passportName != ''">
              <div class="box-item">
                <strong class="box-item-title">{{
                  $t("overview.namepassport")
                }}</strong>
                <p class="box-item-text">{{ user.passportName }}</p>
              </div>
            </div>
            <div :class="overviewClass">
              <div class="box-item">
                <strong class="box-item-title">{{
                  $t("label.birthdate")
                }}</strong>
                <p class="box-item-text">{{ user.birthDate }}</p>
              </div>
            </div>
            <div :class="overviewClass">
              <div class="box-item">
                <strong class="box-item-title"
                  >{{ $t("label.sex") }}
                  <span
                    class="fa fa-info-circle toggle"
                    @click="toggleCollapse('gender')"
                  ></span
                ></strong>

                <p class="box-item-text">{{ user.gender }}</p>
              </div>
            </div>
            <a
              href="#"
              class="edit fa fa-pencil"
              v-on:click.prevent="toggleEdit"
              ><span class="hidden">Bewerken</span></a
            >
          </div>
          <div id="gender" class="help-bubble collapse" style="">
            <div class="card">
              <div class="card-body">
                <p class="help-bubbletext">{{ $t("tooltip.gender") }}</p>
              </div>
            </div>
          </div>
        </div>

        <form class="form validate" v-if="edit == true">
          <div class="row">
            <div class="col-md-8 border-right">
              <div class="row">
                <div class="col-md-6">
                  <div class="box-item">
                    <div class="form-group">
                      <strong class="box-item-title">{{
                        $t("label.firstname")
                      }}</strong>
                      <p class="box-item-text">{{ user.firstName }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="box-item">
                    <div class="form-group">
                      <strong class="box-item-title">{{
                        $t("label.lastname")
                      }}</strong>
                      <p class="box-item-text">{{ user.lastName }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="box-item">
                    <div class="form-group">
                      <label for="passportName">{{
                        $t("label.namepassport")
                      }}</label>
                      <input
                        autocomplete="no"
                        type="text"
                        class="form-control"
                        name="passportName"
                        id="achternaam_paspoort"
                        v-model="formData.passportName"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="box-item">
                    <div class="form-group footer-group clearfix">
                      <button
                        type="submit"
                        class="btn button orange submit ld-ext-right"
                        v-on:click.prevent="save"
                        v-bind:class="{ running: loading }"
                        :disabled="loading"
                      >
                        <span>{{ $t("button.save") }}</span>
                        <div class="ld ld-ring ld-spin"></div>
                      </button>
                      <button
                        class="btn button blue fa fa-times"
                        v-on:click.prevent="toggleEdit"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-item">
                <strong class="box-item-title"
                  ><span class="fa fa-info-circle"></span>
                  {{ $t("personal.sexbirthdate") }}</strong
                >
                <p
                  class="box-item-text"
                  v-html="$t('personal.sexbirthdatedesc')"
                ></p>
              </div>
            </div>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import PortalLoaderCircle from "./../layout/PortalLoaderCircle.vue";
import * as types from "../../../store/mutation-types";

export default {
  name: "portal-personaldetails-editname",
  data: function () {
    return {
      edit: false,
      loading: false,
      formData: {},
      backendErrors: [],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["loadingStates"]),
    fullName: function () {
      return this.user.firstName + " " + this.user.lastName;
    },
    overviewClass: function () {
      return this.user.passportName ? "col-lg-6" : "col-lg-4";
    },
  },
  methods: {
    toggleCollapse(target) {
      let element = document.getElementById(target);
      if (element.className == "show") {
        element.className = "hide";
      } else {
        element.className = "show";
      }
    },
    hasError(field) {
      var backendErrorFields = this.backendErrors.map((error) => error.field);
      if (this.errors.has(field) || backendErrorFields.indexOf(field) > -1) {
        return true;
      }
      return false;
    },
    toggleEdit: function (event) {
      this.edit = !this.edit;
      if (this.edit) {
        this.formData = _.cloneDeep(this.$store.state.user.user);
      }
    },
    save: function (event) {
      if (!this.loading) {
        this.loading = true;
        this.$store
          .dispatch("user/update", {
            passportName: this.formData.passportName,
            type: "general",
          })
          .then((response) => {
            this.loading = false;
            this.backendErrors = [];
            this.edit = false;
            this.$store.commit("app/" + types.SET_NOTICE, {
              message: this.$i18n.t("alert.success"),
              type: "alert-success",
            });
            setTimeout(() => {
              this.$store.commit("app/" + types.RESET_NOTICE);
            }, 2500);
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.edit = false;
            this.backendErrors = error.errors;
          });
      }
    },
  },
  components: {
    PortalLoaderCircle,
  },
};
</script>

<style scoped>
.toggle {
  margin-left: 5px;
  color: #008abb;
}
.card-body {
  background-color: #008abb;
  background-image: url("@/assets/images/topography.png");
  background-repeat: repeat;
  background-position: center center;
  background-blend-mode: multiply;
}

.help-bubbletext {
  color: #fff;
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 0.875em;
}
</style>
