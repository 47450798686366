// app store
export const LOADING_USER = 'LOADING_USER';
export const USER_LOADED = 'USER_LOADED';
export const LOADING_APP = 'LOADING_APP';
export const APP_LOADED = 'APP_LOADED';
export const LOADING_TRANSLATIONS = 'LOADING_TRANSLATIONS';
export const TRANSLATIONS_LOADED = 'TRANSLATIONS_LOADED';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_PORTAL = 'SET_PORTAL';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_NOTICE = 'SET_NOTICE';
export const RESET_NOTICE = 'RESET_NOTICE';
export const RESET_APP = 'RESET_APP';
export const UPDATE_UPDATEDAT = 'UPDATE_UPDATEDAT';

// user store
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER = 'SET_USER';
export const SET_USER_FAILED = 'SET_USER_FAILED';
export const RESET_USER = 'RESET_USER';

// auth store
export const RESET_CONFIRMED = 'RESET_CONFIRMED';
export const CONFIRMED = 'CONFIRMED';
export const NOT_CONFIRMED = 'NOT_CONFIRMED';
export const CONFIRMED_TYPES = 'CONFIRMED_TYPES';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const SET_TYPE = 'SET_TYPE';
export const RESET_TYPES = 'RESET_TYPES';
export const TOKEN_SENT = 'TOKEN_SENT';
export const TOKEN_NOT_SENT = 'TOKEN_NOT_SENT';
export const AUTHENTICATED = 'AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const VERIFIED = 'VERIFIED';
export const NOT_VERIFIED = 'NOT_VERIFIED';
export const RESET_AUTH = 'RESET_AUTH';
export const SET_MEDICALLOGIN = 'SET_MEDICALLOGIN';
export const SET_PORTALLOGIN = 'SET_PORTALLOGIN';
export const SET_RESETLASTNAME = 'SET_RESETLASTNAME';
export const SET_RESETCONTACT = 'SET_RESETCONTACT';

