<template>
    <transition name="fade">
        <div class="alert" v-if="isActive" v-bind:class="[notice.type]">
            {{ notice.message }}
            <div class="alert-close" v-on:click="close">
                <span class="fa fa-times"></span>
            </div>
        </div>
    </transition>
</template>

<script>
	import {mapState} from 'vuex';
	import * as types from '../../store/mutation-types';

  export default {
  	name: 'app-alert',
		data: function() {
			return {
				// @todo replace notactive with vue animation options
				// notActive: false
			}
		},
		computed: {
      ...mapState ('app', [
				'notice',
			]),
			isActive() {
				return (this.notice.message) ? true : false;
			}
		},
		methods: {
			close: function(event){
				this.$store.commit('app/'+types.RESET_NOTICE);
				// this.notActive = true;
			}
        }
  }
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
