<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                    <div class="portal-wrapper">
                        <div class="portal-wrapper-header portal-wrapper-header-login">
                            <h2 class="title">{{ $t('login.title') }} </h2>
                            <div class="lead">
                                <p>{{ $t('login.intro') }}</p>
                            </div>
                        </div>

                        <div class="portal-wrapper-content portal-wrapper-content-login">
                            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" id="login" class="form-wrapper form validate login">
                                <div class="form-wrapper-top mp-r">
                                    <div class="row">
                                        <div class="form-group form-group-phone col-sm-6" v-bind:class="{'error': errors.mobile, 'success': mobileIsValid}">
                                            <label for="06-nummer"><span class="fa fa-mobile-phone"></span><span class="hidden">{{ $t('placeholder.mobile') }}</span></label>
                                            <Field autocomplete="off" type="number" class="form-control phone phoneNL required" name="mobile" id="06-nummer" :placeholder="$t('placeholder.mobile')" data-msg="Verplicht" data-msg-phoneNL="Voer een telefoonnummer in" v-model="mobile"  :disabled="!mobileIsRequired"/>
                                        </div>
                                        <div class="form-group form-group-email col-sm-6" v-bind:class="{'error': errors.email, 'success': emailIsValid}">
                                            <label for="E-mailadres"><span class="fa fa-envelope"></span><span class="hidden">{{ $t('placeholder.email') }}</span></label>
                                            <Field autocomplete="off" type="email" class="form-control email required" name="email" id="E-mailadres" :placeholder="$t('placeholder.email')" data-msg="Verplicht" data-msg-email="Voer een e-mailadres in" v-model="email" :disabled="!emailIsRequired"/>
                                        </div>
                                        <span class="of">{{ $t('label.or') }}</span>

                                        <span :data-tooltip="$t('tooltip.emailPhone')" class="tooltip-left tooltip-one">
                                            <i class="fa fa-info-circle"></i>
                                        </span>

                                        <div class="col-md-12">
                                            <h3 class="subtitle">{{ $t('label.birthdate') }}</h3>
                                        </div>
                                        <div class="form-group select-group col-sm-3 padding-right-10" v-bind:class="{'error': errors.day, 'success': isNumber(day)}">
                                            <label for="day">
                                                <span class="fa fa-calendar-o"></span>
                                                <span class="hidden">{{ $t('placeholder.day') }}</span>
                                            </label>
                                            <Field as="select" autocomplete="off" class="form-control select-day required" name="day" id="day" data-msg="Verplicht" v-model="day">
                                                <option value="" disabled="disabled" selected="selected" class="hidden">{{ $t('placeholder.day') }}</option>
                                                <option v-for="dayNr in days" :value="dayNr" :key="dayNr">{{ dayNr }}</option>
                                            </Field>
                                        </div>
                                        <div class="form-group select-group col-sm-6 padding-right-10 padding-left-10" v-bind:class="{'error': errors.month, 'success': isNumber(month)}">
                                            <label for="month"><span class="hidden">Maand</span></label>
                                            <Field as="select" autocomplete="off" class="form-control select-month required" name="month" id="month" data-msg="Verplicht" v-model="month">
                                                <option value="" disabled="" selected="selected" class="hidden">{{ $t('placeholder.month') }}</option>
                                                <option value="01">{{ $t('option.jan') }}</option>
                                                <option value="02">{{ $t('option.feb') }}</option>
                                                <option value="03">{{ $t('option.mar') }}</option>
                                                <option value="04">{{ $t('option.apr') }}</option>
                                                <option value="05">{{ $t('option.may') }}</option>
                                                <option value="06">{{ $t('option.jun') }}</option>
                                                <option value="07">{{ $t('option.jul') }}</option>
                                                <option value="08">{{ $t('option.aug') }}</option>
                                                <option value="09">{{ $t('option.sep') }}</option>
                                                <option value="10">{{ $t('option.oct') }}</option>
                                                <option value="11">{{ $t('option.nov') }}</option>
                                                <option value="12">{{ $t('option.dec') }}</option>
                                            </Field>
                                        </div>
                                        <div class="form-group select-group col-sm-3 padding-left-10" v-bind:class="{'error': errors.year, 'success': isNumber(year)}">
                                            <label for="year">
                                                <span class="hidden">{{ $t('placeholder.year') }}</span>
                                            </label>
                                            <Field as="select" autocomplete="off" class="form-control select-year required" name="year" id="year" data-msg="Verplicht" v-model="year">
                                                <option disabled="disabled" selected="selected" class="hidden" value="">{{ $t('placeholder.year') }}</option>
                                                <option v-for="yearNr in years" :value="yearNr" :key="yearNr">{{ yearNr }}</option>
                                            </Field>

                                            <span :data-tooltip="$t('tooltip.birthday')" class="tooltip-left tooltip-two">
                                                <i class="fa fa-info-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="centered overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
                                        {{ errorMessage }}
                                    </div>
                                    <div class="form-wrapper-bottom">
                                        <div class="row">
                                            <Field autocomplete="off" type="string" class="form-control" name="petsName" id="petsName" v-model="petsName" data-msg="Verplicht" data-msg-email="Voer een e-mailadres in"/>
                                            <div class="form-group col-md-12 centered mobile-no-padding" v-bind:class="{'error': errors.recaptcha}">
                                                <div class="g-recaptcha" :data-sitekey="rcapt_sig_key" v-bind:class="{'error': captchaError}">
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12 centered">
                                                <button type="submit" class="btn button orange" @click="attempts++" v-bind:class="{loading: loading}">
                                                    <span class="text">{{ $t('button.login') }}</span>
                                                    <div class="loader">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div class="portal-wrapper-footer">
                            <span v-html="$t('label.faq')"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import $ from 'jquery';
	import {mapState} from 'vuex';
    import * as yup from 'yup';
    import { Form, Field } from 'vee-validate';
    

  export default {
    name: 'login',
    data: function() {
      return {
        attempts: 0,
        mobile: '',
        email: '',
        name: '',
        loading: false,
        errorMessage: false,
        errors: '',
        backendErrors: [],
        captchaError: false,
        day: '',
        month: '',
        year: '',
        captchaReady: false,
        checkingInterval: null,
        rcapt_sig_key: '6LfLMj8UAAAAAC_7UxCTBLY2CLNoIju0gIOBilvJ',
        rcapt_id: 0, // will be used later
      }
    },
    components: {
      Form,
      Field,
    },
    setup() {
      const schema = yup.object({
        email: yup.string().test(function(value){
            const {mobile} = this.parent;
            if (!mobile) return value != ''
            return true
        }),
        mobile: yup.string().test(function(value){
            const {email} = this.parent;
            if (!email) return value != ''
            return true
        }),
        // }),
        day: yup.number().required(),
        month: yup.number().required(),
        year: yup.number().required(),
        petsName: yup.string().length(0), // Honeypot field, this should always be empty!
      });
      return {
          schema
      };
    },
    computed: {
        ...mapState ('auth', [
                'isConfirmed',
                'portalLogin'
			]),
        ...mapState ('app', [
				'locale'
			]),
        days: function() {
            let days = [];
            for (let index = 1; index <= 31; index++) {
            days.push(index);
            }
            return days;
        },
        years: function() {
            const currYear = new Date().getFullYear();
            const min = currYear - 17;
            const max = currYear - 56;
            let years = [];
            for (let index = min; index >= max; index--) {
            years.push(index);
            }
            return years;
        },
        birthdate: function() {
            return this.day + '-' + this.month + '-' + this.year;
        },
        emailIsRequired() {
            return (this.mobile.length > 0 && this.email.length == 0) ? false : true;
        },
        mobileIsRequired() {
            return (this.email.length > 0 && this.mobile.length == 0) ? false : true;
        },
        mobileIsValid() {
            return (this.mobile.length > 0 && !this.errors.mobile) ? true : false;
        },
        emailIsValid() {
            return (this.email.length > 0 && !this.errors.email) ? true : false;
        },
        dayIsValid() {

            this.schema.isValid({day: this.day}).then(() => {return true});
            return false;
        },
        monthIsValid() {
            this.schema.isValid({month: this.month}).then(() => {return true});
            return false;
        },
        yearIsValid() {
        },
    },
    watch: {
        locale: function() {
            this.$nextTick(function(){
                if (this.day == 'Dag' || this.day == 'Day') {
                    this.day = this.$i18n.t('placeholder.day');
                }
                if (this.month == 'Maand' || this.month == 'Month') {
                    this.month = this.$i18n.t('placeholder.month');
                }
                if (this.year == 'Jaar' || this.year == 'Year') {
                    this.year = this.$i18n.t('placeholder.year');
                }
            })

            // reload recaptcha when locale is changed
            var recaptcha = $('.g-recaptcha').find('iframe');
            var language = this.locale;

            if (recaptcha.length) {
                var src = recaptcha.attr('src');
                src = src.replace(/(hl=)[^\&]+/, `$1${language}`);
                recaptcha.attr('src', src);
            }
        },
        attempts: function() {
            // After 3 attempts and when captcha is not loaded already, load google Recaptcha.
            if (this.attempts >= 3 && !this.captchaReady) {
                // Load captcha
                this.rcapt_id = grecaptcha.render($('.g-recaptcha')[0], {sitekey: this.rcapt_sig_key});
                // Set captcha ready to true.
                this.captchaReady = true;
            }
        }
    },
    methods: {
        hasError(field) {
            var backendErrorFields = this.backendErrors.map(error => error.field);
            if (this.errors.has(field) || (backendErrorFields.indexOf(field) > -1)) {
                return true;
            }
            return false;
        },
        validateRecaptcha() {
            if (this.attempts < 3) {
                return true;
            }
            let response = grecaptcha.getResponse(this.rcapt_id);
            if (response.length > 0) {
                delete this.backendErrors.recaptcha;
                return true;
            }

            this.backendErrors['recaptcha'] = '-';
            return false;
        },
        isNumber(value) {
            if (typeof value != "string") return false // we only process strings!
            return !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(value)) // ...and ensure strings of whitespace fail
        },
        onSubmit() {
            this.confirm();
        },
        confirm: function(event) {
            if (!this.loading) {
                if (this.validateRecaptcha()) {
                    this.captchaError = false;
                } else {
                    this.captchaError = true;
                    return;
                }
                this.backendErrors = [];
                this.errorMessage = false;
                this.loading = true;

                let user = {
                    birthdate: this.birthdate,
                    // recaptcha: grecaptcha.getResponse(this.rcapt_id)
                };

                if (this.mobile && this.email) {
                    user.mobile = this.mobile;
                    user.email = this.email;
                } else if (this.mobile && !this.email) {
                    user.mobile = this.mobile;
                } else {
                    user.email = this.email;
                }

                this.$store.dispatch('auth/confirm', user).then((response) => {
                    this.loading = false;
                    // redirect
                    if (this.isConfirmed === true) {
                        this.$router.push({name: 'login-authenticate', params: {driver: response?.driver}});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.backendErrors = error.errors;
                    if (error.errors && error.errors.recaptcha) {
                        this.errorMessage = this.$i18n.t('error.recaptcha');
                        // reset captcha cause it has taken to long to validate on server side
                        grecaptcha.reset();
                    } else {
                        this.errorMessage = this.$i18n.t('error.incorrect');
                    }
                });
            }
        }
    }
  }
</script>
<style scoped>
#petsName {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}

</style>
