<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
					<div class="portal-wrapper">

						<div class="portal-wrapper-header portal-wrapper-header-login">
							<h2 class="title">{{ $t('idin.statustitle') }}</h2>
							<div class="lead">
								<p>{{ $t('idin.firstlogin') }}</p>
							</div>
						</div>

						<div class="portal-wrapper-content portal-wrapper-content-login">
							<form class="form-wrapper form">
								<div class="centered">
									<template v-if="loading">
										<div class="placeholder-loader">
											<portal-loader-circle></portal-loader-circle>
											<div class="verified verified-busy">
												<p>{{ $t('idin.waiting') }}</p>
											</div>
										</div>
									</template>
									<template v-if="isVerified">
										<div class="placeholder-loader">
											<div class="verified verified-correct">
												<span class="fa fa-check animated zoomIn"></span>
											</div>
										</div>
									</template>
									<template v-if="!isVerified && !errorMessage && !loading">
										<div class="placeholder-loader">
											<div class="verified verified-failed">
												<span class="fa fa-times animated zoomIn"></span>
												<p>{{ $t('idin.failed') }}</p>
											</div>
											<div class="portal-wrapper-footer">
												<a href="tel:0711234567" target="_blank">{{ $t('idin.callto') }}</a><br/>
												<a href="mailto:portaal@matchis.nl" target="_blank">{{ $t('idin.mailto') }}</a>
											</div>
										</div>
									</template>
									<template v-if="errorMessage">
										<div class="overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
											<span v-html="errorMessage"></span>
										</div>
										<div class="portal-wrapper-footer">
											<router-link to="/inloggen/idin"><a>{{ $t('error.wrong') }}</a></router-link>
										</div>
									</template>
								</div>

							</form>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {mapState} from 'vuex';
	import PortalLoaderCircle from './portal/layout/PortalLoaderCircle.vue';

  export default {
		name: 'login-idin-status',
    data: function() {
      return {
				loading: true,
				errorMessage: false
      }
		},
		computed: {
      ...mapState ('auth', [
				'isVerified',
				'availableTypes'
			])
		},
		components: {
			PortalLoaderCircle
		},
		mounted()  {
			this.$store.dispatch('auth/verify', {trxid: this.$route.query.trxid, ec: this.$route.query.ec}).then((response) => {
				if (this.isVerified === true) {
                    this.$store.dispatch('user/fetch').then((response) => {
                        this.$router.push({name: 'portal-medicaldata'});
                    });
				}
				// some error occured apparently stop loading
				this.loading = false;
				if (response.status == 'open') {
					this.errorMessage = this.$i18n.t('error.idintrxopen');
				} else if (response.status == 'pending') {
					this.errorMessage = this.$i18n.t('error.idintrxopen');
				} else if (response.status == 'failure') {
					this.errorMessage = this.$i18n.t('error.idintrxfailed');
				} else if (response.status == 'expired') {
					this.errorMessage = this.$i18n.t('error.idintrxexpired');
				} else if (response.status == 'cancelled') {
					this.errorMessage = this.$i18n.t('error.idintrxabort');
				}
			}).catch((error) => {
				this.loading = false;
				if (error.message == 'iDIN service error') {
					if (error.data.errorCode == 500) {
						// code related error with iDIN probably no consumer message provided use custom
						this.errorMessage = this.$i18n.t('error.unknown');
					} else {
						this.errorMessage = error.data.consumerMessage;
					}
				} else if (error.status_code == 422 && error.errors.hasOwnProperty('error')) {
					this.errorMessage = error.errors.error;
				} else {
					this.errorMessage = this.$i18n.t('error.unknown');
				}
			});
		}
	}
</script>
