<template>
	<div class="col-md-9">
		<div class="row">
			<portal-personaldetails-editname></portal-personaldetails-editname>
			<div class="col-md-12">
				<div class="row">
					<portal-personaldetails-editcontact></portal-personaldetails-editcontact>
					<portal-personaldetails-editpersonal></portal-personaldetails-editpersonal>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PortalPersonaldetailsEditname from './personal_details/PersonaldetailsEditname.vue';
	import PortalPersonaldetailsEditcontact from './personal_details/PersonaldetailsEditcontact.vue';
	import PortalPersonaldetailsEditpersonal from './personal_details/PersonaldetailsEditpersonal.vue';

	export default {
		name: 'portal-personal-details',
		components: {
			PortalPersonaldetailsEditname,
			PortalPersonaldetailsEditcontact,
			PortalPersonaldetailsEditpersonal
		}
	}
</script>
