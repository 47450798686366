<template>
	<header :class="headerSmall">
		<div class="container">
			<div class="navbar">
				<div class="navbar-brand">
					<h1 class="hidden">Matchis Donorportaal</h1>
					<img :src="getLocaleLogo(locale)" alt="matchis donorportaal" class="img-responsive">
				</div>

                <a :href="$t('link.faq')" target="_blank" class="btn button orange home"><span>{{ $t('button.faq') }}</span></a>

				<div class="clearfix" id="navbar-header">
					<ul class="nav navbar-nav" id="utilities">
						<li class="nav-item">
							<button class="nav-link" id="headerDropdownLanguage" data-toggle="collapse" data-target="#languageHeaderDropdown" aria-expanded="false" aria-controls="languageHeaderDropdown" v-on:click.prevent="locales" v-on-click-outside="closeLocales" v-bind:class="{collapsed: collapsed, active: loading}">
								<img :src="currentLocaleImg" :alt="currentLocale" class="flag" />
								<span class="hidden-xs">{{ currentLocale }}</span>
								<svg class="arrow" width="11px" height="7px" viewBox="0 0 11 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g id="Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="home-copy-2" transform="translate(-1313.000000, -20.000000)" fill="#FFFFFF"> <g id="iconmonstr-arrow-24" transform="translate(1318.500000, 23.500000) rotate(90.000000) translate(-1318.500000, -23.500000) translate(1315.000000, 18.000000)"> <polygon id="Shape" points="1.803375 10.5 0 8.75 3.5 5.25 0 1.75 1.803375 0 7 5.25"></polygon> </g> </g> </g></svg>
							</button>
							<div class="collapse" aria-labelledby="headerDropdownLanguage" id="languageHeaderDropdown" v-bind:class="{in: collapsed}">
								<div class="collapse-inner">
									<a href="#" v-for="(locale, key, index) in portal.locales" v-if="locale != currentLocale" :key="index" v-on:click.prevent="changeLocale(key)">
										<img :src="getLocaleImg(locale)" :alt="locale" class="flag"/>
										<span class="hidden-xs">{{ locale }}</span>
									</a>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<app-alert></app-alert>

			</div> <!-- /navbar -->
		</div>
	</header>
</template>

<script>
	import {mixin as onClickOutside} from 'vue-on-click-outside';
	import AppAlert from './../app/AppAlert.vue';
	import {mapState} from 'vuex';

  export default {
		name: 'app-header',
		data() {
			return {
				collapsed: false,
				loading: false
			}
		},
		mixins: [onClickOutside],
		components: {
      AppAlert
		},
		methods: {
			changeLocale(locale) {
				this.loading = true;
				this.$store.dispatch('app/fetchTranslations', locale).then((response) => {
					this.$i18n.setLocaleMessage(locale, response.translations);
					this.$i18n.locale = locale;
                    localStorage.setItem('selectedLang', locale);
                    // get user details with other locale info when loggedin
					if (this.isConfirmed && this.isAuthenticated) {
						this.$store.dispatch('user/fetch');
					}
					this.loading = false;
					this.collapsed = false;
				}).catch((error) => {
					this.loading = false;
					this.collapsed = false;
				});
			},
			locales() {
				this.collapsed = !this.collapsed;
			},
			closeLocales() {
				this.collapsed = false;
			},
			getLocaleImg(value) {
				return '/assets/images/' + value.toLowerCase().trim() + '-flag.png';
			},
			getLocaleLogo(value) {
				return '/assets/images/logo-' + value.toLowerCase().trim() + '.png';
			}
		},
		computed: {
			currentLocale() {
				return this.portal.locales[this.locale];
			},
			currentLocaleImg() {
				return this.getLocaleImg(this.currentLocale);
			},
			currentLocaleLogo() {
				return this.getLocaleLogo(this.currentLocale);
			},
      ...mapState ('app', [
				'loadingStates',
				'locale',
				'portal'
			]),
      ...mapState ('auth', [
				'isAuthenticated',
				'isConfirmed'
			]),
			headerSmall() {
				return {
					'header-small': this.$route.meta.hasOwnProperty('isLogin')
				}
			}
		}
  }
</script>
