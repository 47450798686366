import userService from '../../services/user-service';
import * as types from '../mutation-types';

export default {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
  	[types.UPDATE_USER] (state, user) {
			// only update if user has this properties available
      for (var property in user) {
				if (state.user.hasOwnProperty(property)) {
					state.user[property] = user[property];
				}
      }
		},
    [types.SET_USER] (state, user) {
      state.user = user;
    },
    [types.SET_USER_FAILED] (state, user) {
			// check if we come from 4xx errors
			// only update user with old data if we have a filled user, if not its been cleared by interceptors
			if (state.user) {
				state.user = user;
			}
		},
		[types.RESET_USER] (state) {
			// @todo reset every proptery of user object for error problems
			state.user = null;
		}
  },
  actions: {
    fetch ({state, commit, rootState}) {
			commit('app/'+types.LOADING_USER, null, {root: true}); // issue here..not adopted by vuex in devtools, i guess its to early for that.
      return userService.fetch().then((response) => {
				commit('app/'+types.USER_LOADED, null, {root: true});
				commit(types.SET_USER, response);
				return Promise.resolve(response);
			}).catch((error) => {
				commit(types.SET_USER_FAILED, null);
				return Promise.reject(error);
			});
    },
    fetchAddress ({state, commit, rootState}, address) {
        return userService.fetchAddress(address).then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    update ({state, commit}, user) {
        var savedUser = state.user;
        return userService.update(user).then((response) => {
            commit(types.UPDATE_USER, response);
            return Promise.resolve(response);
        }).catch((error) => {
            commit(types.SET_USER_FAILED, savedUser);
            return Promise.reject(error);
        });			
    }		
  }
}
