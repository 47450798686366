<template>
	<div class="col-md-12">
		<div class="box">

			<template v-if="loadingStates.user">
				<portal-loader-circle></portal-loader-circle>
			</template>
			<template v-else>
				<div class="row">
					<div class="col-md-12">
						<div class="box-item">
							<strong class="box-item-title">{{ $t('label.status') }}</strong>
							<p class="box-item-text">{{ user.stateDescr }}</p>
						</div>
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import PortalLoaderCircle from './../layout/PortalLoaderCircle.vue';

	export default {
		name: 'portal-overview-status',
		computed: {
    	...mapState ('user', [
      	'user'
			]),
    	...mapState ('app', [
      	'loadingStates'
			])
		},
		components: {
			PortalLoaderCircle
		}
	}
</script>
