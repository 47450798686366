import {HTTP} from './http-common';

export default {
  init: function(locale) {
		return HTTP.get('portal/dpm/'+locale).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
  fetchTranslations: function(locale) {
		return HTTP.get('translations/dpm/'+locale).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	}	
}
