<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
					<div class="portal-wrapper">

						<div class="portal-wrapper-header portal-wrapper-header-login">
							<h2 class="title">{{ $t('verify.lastnametitle') }}</h2>
							<div class="lead">
								<p v-html="$t('verify.lastnameintro')"></p>
							</div>
						</div>

						<div class="portal-wrapper-content portal-wrapper-content-login">
							<Form @submit="advance" :validation-schema="schema" v-slot="{ errors }" class="form-wrapper form validate">
								<div class="form-wrapper-top">
                                    <div class="row">
										<div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
													<h3 class="heading">{{ $t('verify.lastnamedescription') }}</h3>
												</div>
                                            </div>

                                            <div class="form-group label-on-top" v-bind:class="{'error': errors.lastname, 'success': lastnameIsValid}">
                                                <label for="Lastname"><span >{{ $t('label.lastname') }}</span></label>
                                                <Field autocomplete="off" type="text" class="form-control required" name="lastname" id="Lastname" :placeholder="$t('verify.lastnamelabel')" data-msg="Verplicht" v-model="lastName" data-vv-delay="1000" />
                                            </div>
                                            <div class="form-group label-on-top" v-bind:class="{'error': errors.lastnamePassport, 'success': lastnamePassportIsValid}">
                                                <label for="LastnamePassport"><span >{{ $t('label.namepassport') }}</span></label>
                                                <Field autocomplete="off" type="text" class="form-control" name="lastnamePassport" id="LastnamePassport" :placeholder="$t('verify.lastnamelabelpassport')" data-msg="Verplicht" v-model="passportName" data-vv-delay="1000" />
                                            </div>
                                        </div>
                                    </div>
								</div>
								<div class="centered overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
									{{ errorMessage }}
								</div>
								<div class="form-wrapper-bottom">
									<div class="row">
										<div class="form-group col-md-12 centered">
											<button type="submit" class="btn button orange" v-bind:class="{loading: loading}">
												<span class="text">{{ $t('button.proceed') }}</span>
												<div class="loader">
													<span></span>
													<span></span>
													<span></span>
												</div>
											</button>
										</div>
									</div>
								</div>
							</Form>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import * as yup from 'yup';
    import { Form, Field } from 'vee-validate';

	export default {
	    name: 'verify-lastname',
	    data: function () {
	        return {
	            lastName: this.$store.state.user.user.lastName,
	            passportName: this.$store.state.user.user.passportName,
	            loading: false,
                errors: [],
	            errorMessage: false,
	            backendErrors: []
	        }
	    },
        components: {
            Form,
            Field,
        },
        setup() {
            const schema = yup.object({
                lastname: yup.string().required(),
            });
            return {
                schema
            };
        },
	    computed: {
	        lastnameIsValid() {
	            return (this.lastName.length > 0 && !this.hasError('lastname')) ? true : false;
	        },
	        lastnamePassportIsValid() {
	            return (this.passportName.length > 0 && !this.hasError('lastnamePassport')) ? true : false;
	        },
	    },
	    methods: {
	        hasError(field) {
	            var backendErrorFields = this.backendErrors.map(error => error.field);
	            if (this.errors[field] || (backendErrorFields.indexOf(field) > -1)) {
	                return true;
	            }
	            return false;
	        },
	        advance: function (event) {
                this.backendErrors = [];
                this.errorMessage = false;
                this.loading = true;

                let user = {
                    'type': 'general',
                    'lastName': this.lastName,
                    'passportName': this.passportName,

                };
                this.$store.dispatch('user/update', user).then((response) => {
                    this.loading = false;
                    this.$router.push({
                        name: 'portal-overview'
                    });
                }).catch((error) => {
                    this.loading = false;
                    this.backendErrors = error.errors;
                });
	        }
	    }
	}
</script>
