<template>
    <div class="modal modal--twosides" v-if="showModal">
        <div class="modal-content">
             <slot name="modal-content"></slot>
        </div>
    </div>
</template>



<script>

export default {
    name: "modal",
    data: function() {
        return {
            showModal: false,
        }
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
          const el = document.body;

          if (addRemoveClass === 'addClass') {
            el.classList.add(className);
          } else {
            el.classList.remove(className);
          }
        },
        close() {
                this.showModal = false;
                this.toggleBodyClass('removeClass', 'showModal');
        },
        open() {
                this.showModal = true;
                this.toggleBodyClass('addClass', 'showModal');
        }
    }
}
</script>
