<template>
	<div class="main">
        <modal ref="modal">
            <template slot="modal-content">
                <div class="row equalChildHeight">
                    <div class="col-md-7 no-padding-right">
                        <div class="modal-content--side modal-content--side--left">
                            <h2 class="modal__title">{{ $t('popup.idintitle') }}</h2>
                            <div v-html="$t('popup.idintext')">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 no-padding-left">
                        <div class="modal-content--side modal-content--side--right modal-content--bg modal-content--bg--blue modal-content--vertical modal-content--vertical--middle">
                            <div class="modal-content__box">
                                <img src="/assets/images/idin_71x58.png" alt="iDIN" />
                                <h3 class="modal__title">{{ $t('popup.idinsubtitle') }}</h3>
                                <ul class="checklist" v-html="$t('popup.idinlist')">

                                </ul>
                                <a v-on:click.prevent="modalClose" class="btn button orange">
                                    <span class="text">{{ $t("popup.idinbutton")}}</span>
                                    <div class="loader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <!-- <div class="portal-wrapper"> -->

            <div class="portal-wrapper-header portal-wrapper-header-login">
                <h2 class="title">{{ $t('idin.title') }}</h2>
                <div class="lead">
                    <p>{{ $t('idin.firstlogin') }}</p>
                </div>
            </div>

            <div class="portal-wrapper-content portal-wrapper-content-login">
                <form class="form-wrapper form validate">
                    <div class="idin-message">
                        <div class="media">
                            <div class="media-left">
                                <img class="media-object" src="/assets/images/idin.png" alt="iDin">
                            </div>
                            <div class="media-body">
                                <h3 class="media-heading">{{ $t('idin.subtitle') }}</h3>
                                <p v-html="$t('idin.link')"></p>
                            </div>
                        </div>
                    </div>
                    <div class="form-wrapper-top">
                        <div class="row">
                            <div class="form-group select-group col-md-12">
                                <label for="choose_bank"><span class="hidden">Bank</span></label>
                                <select autocomplete="off" class="form-control select-bank required" name="issuer" id="choose_bank" data-msg="Verplicht" v-model="issuer">
                                    <option disabled selected class="hidden">{{ $t('idin.selectissuer') }}</option>
                                    <optgroup label="Nederland">
                                        <option v-for="issuer in issuersNL" :value="issuer.issuerId">{{ issuer.name }}</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="centered overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
                        {{ errorMessage }}
                    </div>
                    <div class="form-wrapper-bottom">
                        <div class="row">
                            <div class="form-group col-md-12 centered">
                                <button v-on:click.prevent="authenticate" type="submit" class="btn button orange" v-bind:class="{loading: loading}">
                                    <span class="text">{{ $t('button.idin') }}</span>
                                    <div class="loader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
	<!-- </div> -->
</template>

<script>
import Vue from 'vue';
import Modal from './modal/Modal.vue'
import {mapState} from 'vuex';

export default {
    name: 'login-idin',
    components: {
        Modal,
    },
    data: function() {
        return {
            loading: false,
            issuer: this.$i18n.t('idin.selectissuer'),
            errorMessage: false,
        }
    },
    mounted() {
        this.$refs.modal.open();
    },
    computed: {
        ...mapState ('app', [
            'portal',
            'locale'
        ]),
        issuersNL: function() {
            return this.portal.issuers.filter(function (issuer) {
                return issuer.country == 'Nederland';
            })
        },
        issuers () {
            return this.portal.issuers.map(function(elem) {
            return elem.issuerId;
            }).join(",");
        }
    },
    watch: {
        locale: function(locale) {
            this.issuer = this.$i18n.t('idin.selectissuer');
            // hacking mother fucker shit.. this together
        }
    },
    methods: {
        modalClose() {
            this.$refs.modal.close();
        },
        hasError(field) {
            if (this.errors.has(field)) {
                return true;
            }
            return false;
        },
        authenticate: function(event) {
            if (!this.loading) {
                if (this.issuer != this.$i18n.t('idin.selectissuer')) {
                    this.errorMessage = false;
                    this.loading = true;
                    
                    this.$store.dispatch('auth/issuer', this.issuer).then((response) => {
                        this.loading = false;
                        // redirect to issuer
                        if (response.hasOwnProperty('issuerAuthenticationUrl')) {
                            // use .replace to erase session but we dont want that for now
                            window.location.href = response.issuerAuthenticationUrl;
                        }
                    }).catch((error) => {
                        this.loading = false;
                        if (error.message == 'iDIN service error') {
                            if (error.data.errorCode == 500) {
                                // code related error with iDIN probably no consumer message provided use custom
                                this.errorMessage = this.$i18n.t('error.unknown');
                            } else {
                                this.errorMessage = error.data.consumerMessage;
                            }
                        } else {
                            this.errorMessage = this.$i18n.t('error.unknown');
                        }
                    });
                }
            }
        }
    }
}
</script>
