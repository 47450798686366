<template>
	<div class="main">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
					<div class="portal-wrapper">

						<div class="portal-wrapper-header portal-wrapper-header-login">
							<h2 class="title">{{ title }}</h2>
							<div class="lead">
								<p>{{ leadDesc }}</p>
							</div>
						</div>

						<div class="portal-wrapper-content portal-wrapper-content-login">
							<Form @submit="advance" :validation-schema="schema" v-slot="{ errors }" class="form-wrapper form validate">
								<div class="form-wrapper-top">
									<div class="row">
										<template v-if="!availableTypes.email">
											<div class="form-group form-group-email col-md-12" v-bind:class="{'error': errors.email, 'success': !errors.mobile}">
												<label for="E-mailadres"><span class="fa fa-envelope"></span><span class="hidden">{{ $t('label.email') }}</span></label>
												<Field autocomplete="off" type="email" class="form-control email required" name="email" id="E-mailadres" :placeholder="$t('placeholder.email')" data-msg="Verplicht" data-msg-email="Voer een e-mailadres in" v-model="email" data-vv-delay="1000" />
											</div>
										</template>
										<template v-if="!availableTypes.mobile">
											<div class="form-group form-group-phone col-md-12" v-bind:class="{'error': errors.mobile, 'success': !errors.mobile}">
												<label for="06-nummer"><span class="fa fa-mobile-phone"></span><span class="hidden">{{ $t('label.mobile') }}</span></label>
												<Field autocomplete="off" type="number" class="form-control phone phoneNL required" name="mobile" id="06-nummer" :placeholder="$t('placeholder.mobile')" data-msg="Verplicht" data-msg-phoneNL="Voer een telefoonnummer in" v-model="mobile" data-vv-delay="1000" />
											</div>
										</template>
									</div>
								</div>
                                
								<div class="centered overall-error" v-bind:class="{'is-active': errorMessage}" id="error-message">
									{{ errorMessage }}
								</div>
								<div class="form-wrapper-bottom">
									<div class="row">
										<div class="form-group col-md-12 centered">
											<button type="submit" class="btn button orange" v-bind:class="{loading: loading}">
												<span class="text">{{ $t('button.proceed') }}</span>
												<div class="loader">
													<span></span>
													<span></span>
													<span></span>
												</div>
											</button>
										</div>
									</div>
								</div>
							</Form>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {mapState} from 'vuex';
    import * as yup from 'yup';
    import { Form, Field } from 'vee-validate';
    

  export default {
		name: 'verify-contact',
		data: function() {
			return {
				mobile: '',
				email: '',
				loading: false,
				errorMessage: false,
				backendErrors: [],
                missingMobile: '',
                missingEmail: '',
			}
		},
        components: {
            Form,
            Field,
        },
        setup() {
            const schema = yup.object({
                email: yup.mixed().test('emailRequired', 'Email is required',
                    function(value) {
                        if(document.getElementById("E-mailadres")) {
                            return value != ""
                        }

                        return true;
                    }
                ),
                mobile: yup.mixed().test('mobileRequired', 'Mobile is required', 
                    function(value) {
                        if(document.getElementById("06-nummer")) {
                            return value != ""
                        } 
                        return true;
                    }
                ),
            });
            return {
                schema
            };
        },
		computed: {
            ...mapState ('auth', [
                'availableTypes',
                'resetLastname',
                'resetContact',
			]),
			title() {
				// email not available
				// else mobile not available, cause we came here by missing one of the two
				if (this.availableTypes.mobile) {
					return this.$i18n.t('verify.mailmissing');
				}
				return this.$i18n.t('verify.phonemissing');
			},
			leadDesc() {
				// email not available
				// else mobile not available, cause we came here by missing one of the two
				if (this.availableTypes.mobile) {
					return this.$i18n.t('verify.mailmissingdesc');
				}
				return this.$i18n.t('verify.phonemissingdesc');
			},
			mobileIsValid() {
				return (this.mobile.length > 0 && !this.hasError('mobile')) ? true : false;
			},
			emailIsValid() {
				return (this.email.length > 0 && !this.hasError('email')) ? true : false;
			},
		},
		methods: {
			hasError(field) {
                var backendErrorFields = this.backendErrors.map(error => error.field);
				if ((backendErrorFields.indexOf(field) > -1)) {
					return true;
				}
				return false;
			},
			advance: function() {
                this.backendErrors = [];
                this.errorMessage = false;
                this.loading = true;

                let user = {
                    'type': 'general'
                };

                // update email when mobile is available and otherway around
                if (this.availableTypes.mobile) {
                    user.email = this.email;
                } else {
                    user.mobile = this.mobile;
                }
                this.$store.dispatch('user/update', user).then((response) => {
                    this.loading = false;
                    if(this.resetContact) {
                        this.$router.push({name: 'verify-address'})
                    } else if (this.resetLastname) {
                        this.$router.push({name: 'verify-address'})
                    } else {
                        this.$router.push({name: 'portal-overview'})
                    }
                }).catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.backendErrors = error.errors;
                });
					
			}
		}
	}
</script>
