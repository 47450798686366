// The following line loads the standalone build of Vue instead of the runtime-only build,
// so you don't have to do: import Vue from 'vue/dist/vue'
// This is done with the browser options. For the config, see package.json
const { createApp } = require('vue');
import App from './components/App.vue'
import store from './store'
import router from './router'
import VueCookies from 'vue3-cookies'
import { createI18n } from 'vue-i18n'

// only on production
var userLang = localStorage.getItem('selectedLang') || navigator.language || navigator.userLanguage;
const i18n = createI18n({
  locale: (userLang.substring(0, 2) == 'en') ? 'en' : 'nl',
  fallbackLocale: 'nl',
  fallbackWarn: false,
  missingWarn: false
})

const app = createApp(App)
.use(i18n)
.use(store)
.use(router)

app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
});

const vm = app.mount('#app')

if (typeof window.getComputedStyle(document.body).backgroundBlendMode !== 'undefined') {
  document.documentElement.className += ' no-background-blend-mode'
}
