<template>
  <div class="main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="portal-wrapper">
            <portal-header></portal-header>
            <portal-wrapper></portal-wrapper>
            <div class="portal-wrapper-footer">
                <span v-html="$t('label.faq')"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PortalHeader from './portal/layout/PortalHeader.vue';
	import PortalWrapper from './portal/layout/PortalWrapper.vue';
	import * as types from '../store/mutation-types';

  export default {
    name: 'portal',
    components: {
      PortalHeader,
      PortalWrapper
    },
		created () {
			// fetch user only when not fetched yet
			if (!this.$store.state.user.user) {
				this.$store.dispatch('user/fetch');
			}
		}
  }
</script>
