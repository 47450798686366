import Vue from 'vue';
import portalService from '../../services/portal-service';
import * as types from '../mutation-types';

export default {
  namespaced: true,
  state: {
		locale: 'nl',
        notice: {
			type: null,
            message: null,
        },
		portal: {
			issuers: [],
			portalKey: null,
			portalName: null,
			locale: null,
			locales: {},
			translations: {}
		},
    loadingStates: {
			user: false,
			app: false,
			translations: false
		},
		updatedAt: 0,
  },
  mutations: {
  	[types.LOADING_USER] (state) {
			state.loadingStates.user = true;
		},
    [types.USER_LOADED] (state) {
      state.loadingStates.user = false;
		},
  	[types.LOADING_APP] (state) {
			state.loadingStates.app = true;
		},
    [types.APP_LOADED] (state) {
      state.loadingStates.app = false;
		},	
  	[types.LOADING_TRANSLATIONS] (state) {
			state.loadingStates.translations = true;
		},
    [types.TRANSLATIONS_LOADED] (state) {
      state.loadingStates.translations = false;
		},				
		[types.SET_LOCALE] (state, locale) {
			state.locale = locale;
			// Vue.i18n.set(locale);
		},
		[types.SET_PORTAL] (state, portal) {
			state.portal = portal;
			// setup translations
		},
		[types.SET_TRANSLATIONS] (state, response) {
			state.portal.translations = response.translations;
		},
		[types.SET_NOTICE] (state, notice) {
            state.notice.type = notice.type;
            state.notice.message = notice.message;
            
        },
		[types.RESET_NOTICE] (state) {
			state.notice = {
                message: null,
                type: null,
            }
		},		
		[types.RESET_APP] (state) {
			state.loadingStates = {
				user: false,
				app: false,
				translations: false
			};
		},
		[types.UPDATE_UPDATEDAT] (state) {
			state.updatedAt = Date.now();
		},
	},
  actions: {
    init ({state, commit, rootState}) {
			commit(types.LOADING_APP);
      return portalService.init(state.locale).then((response) => {
				commit(types.SET_PORTAL, response);
				commit(types.APP_LOADED);
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error);
			});
		},
		fetchTranslations ({state, commit, rootState}, locale) {
			commit(types.LOADING_TRANSLATIONS);
      return portalService.fetchTranslations(locale).then((response) => {
				commit(types.SET_TRANSLATIONS, {locale: locale, translations: response.translations});
				commit(types.SET_LOCALE, locale);
				commit(types.TRANSLATIONS_LOADED);
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error);
			});
		}
	}	
}
