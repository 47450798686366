<template>
	<div class="col-sm-6 padding-right-10">
		<div class="box">

			<template v-if="loadingStates.user">
				<portal-loader-circle></portal-loader-circle>
			</template>
			<template v-else>
				<div class="row">
					<div class="col-md-12">
						<div class="box-item">
							<strong class="box-item-title">{{ $t('label.data') }}</strong>
							<p class="box-item-text">
								{{ fullName }}<br/>
								{{ user.street }} {{ user.streetNumber }}<br/>
								{{ user.zipcodeNumerals }}{{ user.zipcodeLetters }} {{ user.city }}<br/>
								{{ user.email }}
							</p>
						</div>
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import PortalLoaderCircle from './../layout/PortalLoaderCircle.vue';

	export default {
		name: 'portal-overview-user',
		computed: {
      ...mapState ('user', [
        'user'
			]),
    	...mapState ('app', [
      	'loadingStates'
			]),
			fullName: function() {
				return this.user.firstName + ' ' + this.user.lastName + ' ' + this.user.passportName;
			}
		},
		components: {
			PortalLoaderCircle
		}
	}
</script>
