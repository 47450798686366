<template>
  <div class="col-md-6">
    <div class="box">
      <template v-if="loadingStates.user">
        <portal-loader-circle></portal-loader-circle>
      </template>
      <template v-else>
        <div class="card" v-if="edit == false">
          <div class="row">
            <div class="col-md-12">
              <div class="box-item">
                <strong class="box-item-title">{{ $t("label.email") }}</strong>
                <p class="box-item-text">
                  {{ user.email }}
                  <small v-if="user.emailTemp"
                    >(<b>{{ user.emailTemp }}</b
                    >)</small
                  >
                </p>
              </div>
              <div v-if="user.emailTemp" class="alert alert-info alert-email">
                {{ $t("notice.emailchanged") }}
              </div>
            </div>
            <div class="col-md-12">
              <div class="box-item">
                <strong class="box-item-title">{{ $t("label.mobile") }}</strong>
                <p class="box-item-text">
                  {{ user.mobile }}
                  <small v-if="user.mobileTemp"
                    >(<b>{{ user.mobileTemp }}</b
                    >)</small
                  >
                </p>
              </div>
              <div v-if="user.mobileTemp" class="alert alert-info alert-email">
                {{ $t("notice.mobilechanged") }}
              </div>
            </div>
            <div class="col-md-12">
              <div class="box-item">
                <strong class="box-item-title">{{
                  $t("label.address")
                }}</strong>
                <p class="box-item-text">
                  {{ user.street }} {{ user.streetNumber }}<br />
                  {{ user.zipcodeNumerals }}{{ user.zipcodeLetters }}
                  {{ user.city }}<br />
                  {{ user.country }}
                </p>
              </div>
            </div>
          </div>
          <a href="#" class="edit fa fa-pencil" v-on:click.prevent="toggleEdit"
            ><span class="hidden">Bewerken</span></a
          >
        </div>

        <Form
          class="form validate"
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
          v-if="edit == true"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="box-item">
                <div
                  class="form-group"
                  v-bind:class="{ 'validation-error': errors.email }"
                >
                  <label for="email">{{ $t("label.email") }}</label>
                  <Field
                    autocomplete="no"
                    type="email"
                    class="form-control required email"
                    name="email"
                    id="email"
                    v-model="formData.email"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box-item">
                <div
                  class="form-group"
                  v-bind:class="{ 'validation-error': errors.mobile }"
                >
                  <label for="mobile">{{ $t("label.mobile") }}</label>
                  <Field
                    autocomplete="no"
                    type="text"
                    class="form-control required phoneNL"
                    name="mobile"
                    id="phone"
                    v-model="formData.mobile"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 padding-right-10">
                  <div class="box-item">
                    <div
                      class="form-group"
                      v-bind:class="{
                        'validation-error': errors.zipcodeNumerals,
                      }"
                    >
                      <label for="zipcodeNumerals"
                        >{{ $t("label.zipcode") }}
                        <span class="hidden">cijfers</span></label
                      >
                      <Field
                        autocomplete="no"
                        min="1"
                        max="9999"
                        type="number"
                        class="form-control required number"
                        data-rule-minlength="4"
                        name="zipcodeNumerals"
                        @change="fetchAddress"
                        id="postcode_cijfers"
                        v-model="formData.zipcodeNumerals"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-2 padding-left-10 padding-right-10">
                  <div class="box-item">
                    <div
                      class="form-group"
                      v-bind:class="{
                        'validation-error': errors.zipcodeLetters,
                      }"
                    >
                      <label for="zipcodeLetters"
                        >&nbsp;<span class="hidden"
                          >Postcode letters</span
                        ></label
                      >
                      <Field
                        autocomplete="no"
                        minlength="2"
                        maxlength="2"
                        type="text"
                        class="form-control required"
                        data-rule-minlength="2"
                        name="zipcodeLetters"
                        @change="fetchAddress"
                        id="postcode_letters"
                        v-model="formData.zipcodeLetters"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 padding-left-10">
                  <div class="box-item">
                    <div
                      class="form-group"
                      v-bind:class="{ 'validation-error': errors.streetNumber }"
                    >
                      <label for="streetNumber">{{
                        $t("label.streetnumber")
                      }}</label>
                      <Field
                        autocomplete="no"
                        type="text"
                        class="form-control required"
                        name="streetNumber"
                        id="huisnummer"
                        v-model="formData.streetNumber"
                        @change="fetchAddress"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box-item">
                <div
                  class="form-group"
                  v-bind:class="{ 'validation-error': errors.street }"
                >
                  <label for="street">{{ $t("label.streetname") }}</label>
                  <Field
                    :disabled="isDisabled ? true : null"
                    autocomplete="no"
                    ref="street"
                    type="text"
                    name="street"
                    class="form-control required"
                    v-bind:class="{
                      'is-disabled': isDisabled,
                      'is-loading': loading,
                    }"
                    v-model="formData.street"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box-item">
                <div
                  class="form-group"
                  v-bind:class="{ 'validation-error': errors.city }"
                >
                  <label for="city">{{ $t("label.city") }}</label>
                  <Field
                    autocomplete="no"
                    :disabled="isDisabled ? true : null"
                    type="text"
                    name="city"
                    class="form-control required"
                    v-bind:class="{
                      'is-disabled': isDisabled,
                      'is-loading': loading,
                    }"
                    v-model="formData.city"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12" v-show="isDisabled">
              <a href="#" v-on:click.prevent="forceAddressValidated">{{
                $t("link.correctAddress")
              }}</a>
            </div>
            <!-- <div class="col-md-12">
							<div class="box-item">`
								<div class="form-group radio-group">
									<input type="checkbox" class="form-control" name="nederland" id="nederland" value="ja" checked/>
									<label for="nederland">{{ $t('radio.origin') }}</label>
								</div>
							</div>
						</div> -->
            <div class="col-md-12">
              <div class="box-item">
                <div class="form-group footer-group clearfix">
                  <button
                    type="submit"
                    class="btn button orange submit ld-ext-right sggiosfghasohkfgaiohkfihf"
                    v-bind:class="{ running: loading }"
                    :disabled="loading"
                  >
                    <span>{{ $t("button.save") }}</span>
                    <div class="ld ld-ring ld-spin"></div>
                  </button>
                  <button
                    class="btn button blue fa fa-times"
                    v-on:click.prevent="toggleEdit"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import PortalLoaderCircle from "./../layout/PortalLoaderCircle.vue";
import * as types from "../../../store/mutation-types";
import { Form, Field } from "vee-validate";
import * as yup from "yup";

export default {
  name: "portal-personaldetails-editcontact",
  data: function () {
    return {
      edit: false,
      loading: false,
      errors: [],
      formData: {},
      backendErrors: [],
      addressValidated: false,
      dummyPhonenumbers: ["0612345678", "0611111111", "+31611111111"],
    };
  },
  components: {
    Form,
    Field,
    PortalLoaderCircle,
  },
  setup() {
    const schema = yup.object({
      email: yup.string().email().required(),
      mobile: yup.string().required(),
      zipcodeNumerals: yup
        .number()
        .required()
        .test((value) => value.toString().length === 4),
      zipcodeLetters: yup
        .string()
        .required()
        .matches(/^[A-Za-z]{2}$/),
      streetNumber: yup.string().required().matches(/^\d.*/),
      street: yup.string().required(),
      city: yup.string().required(),
    });
    return {
      schema,
    };
  },
  methods: {
    onSubmit() {
      this.save();
    },
    fetchAddress() {
      if (this.address) {
        this.loading = true;
        this.$store
          .dispatch("user/fetchAddress", this.address)
          .then((response) => {
            this.formData.street = response.street;
            this.formData.city = response.city;
            this.loading = false;
          })
          .catch((error) => {
            this.formData.street = "";
            this.formData.city = "";
            this.loading = false;
          });
      }
    },
    forceAddressValidated() {
      this.addressValidated = true;
      //this.$refs.street.focus();
    },
    hasError(field) {
      var backendErrorFields = this.backendErrors.map((error) => error.field);
      if (this.errors[field] || backendErrorFields.indexOf(field) > -1) {
        return true;
      }
      return false;
    },
    toggleEdit: function (event) {
      this.edit = !this.edit;
      if (this.edit) {
        this.formData = _.cloneDeep(this.$store.state.user.user);
        this.fetchAddress();
        this.formData.email =
          this.formData.emailTemp != null
            ? this.formData.emailTemp
            : this.formData.email;
        this.formData.mobile =
          this.formData.mobileTemp != null
            ? this.formData.mobileTemp
            : this.formData.mobile;
      }
    },
    save: function (event) {
      if (!this.loading) {
        const emailChanged =
          this.user.email === this.formData.email ? false : true;
        this.loading = true;
        this.$store
          .dispatch(
            "user/update",
            {
              email: this.formData.email,
              mobile: this.formData.mobile,
              street: this.formData.street,
              streetNumber: this.formData.streetNumber,
              zipcodeNumerals: this.formData.zipcodeNumerals,
              zipcodeLetters: this.formData.zipcodeLetters,
              city: this.formData.city,
              type: "general",
            },
            emailChanged
          )
          .then((response) => {
            this.loading = false;
            this.backendErrors = [];
            this.edit = false;
            this.$store.commit("app/" + types.SET_NOTICE, {
              message: this.$i18n.t("alert.success"),
              type: "alert-success",
            });
            setTimeout(() => {
              this.$store.commit("app/" + types.RESET_NOTICE);
            }, 2500);
          })
          .catch((error) => {
            this.loading = false;
            this.backendErrors = error.errors;
          });
      }
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("app", ["loadingStates"]),
    address() {
      return (
        this.formData.zipcodeNumerals +
        this.formData.zipcodeLetters.toUpperCase() +
        "+" +
        this.formData.streetNumber
      );
    },
    isDisabled() {
      return !this.addressValidated;
    },
  },
};
</script>
