<template>
	<div class="col-sm-6 padding-left-10">
		<div class="box box-newsletter">

			<template v-if="loadingStates.user">
				<portal-loader-circle></portal-loader-circle>
			</template>
			<template v-else>
				<div class="row">
					<div class="col-md-12">
						<div class="box-item">
							<strong class="box-item-title">{{ $t('label.letter') }}</strong>
							<p class="box-item-text">
								{{ $t('overview.letter') }}
							</p>
							<form class="form form-newsletter-small row" v-bind:class="{disabled: loading}">
								<div class="form-group radio-group col-md-12">
									<input type="checkbox" class="form-control" name="letter-small" id="letter-small-no" value="false" v-model="postsubscribed"/>
									<label for="letter-small-no" v-html="$t('radio.noplease')"></label>
								</div>
							</form>
						</div>
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import _ from 'lodash';
    import * as types from '../../../store/mutation-types'
	import PortalLoaderCircle from './../layout/PortalLoaderCircle.vue';

	export default {
		name: 'portal-overview-newsletter',
		data: function(){
			return {
				loading: false,
				savedLetter: null
			}
		},
		computed: {
			...mapState ('app', [
				'loadingStates'
            ],
            'user', [
      	        'user'
			]),
        
			postsubscribed: {
				get() {
						// stupid fix for vuex stupidness...
						// get cached radio value if new cycle starts apprently by updingt the component with new class, get is called again and is not udated yet
						// it will only updated after ajax call is completed
                    
                    return (this.savedLetter === null) ? !this.$store.state.user.user.postSubscribed : !this.savedLetter
				},
				set(value) {
					if (!this.loading) {
						value = !value;
						this.savedLetter = value;
						this.loading = true;
						this.$store.dispatch('user/update', {
							postsubscribed: value,
							type: 'process'
						}).then((response) => {
							this.loading = false;
                            this.$store.commit('app/'+types.SET_NOTICE, {message: this.$i18n.t('alert.success'), type: "alert-success"});
                            setTimeout(()=>{
                                this.$store.commit('app/'+types.RESET_NOTICE);
                            }, 2500);
						}).catch((error) => {
							this.loading = false;
							// @todo do something better than this, vuex...crappies
							this.savedLetter = this.$store.state.user.user.postsubscribed;
						});
					}
				}
			}
		},
		components: {
			PortalLoaderCircle
		}
	}
</script>
