import {HTTP} from './http-common';

export default {
  confirm: function(user) {
		return HTTP.post('auth/confirm', user).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
  login: function(user) {
		return HTTP.post('login', user).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});		
	},
  logout: function(user) {
		return HTTP.get('logout').then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
	authenticate: function(token) {
		return HTTP.post('auth/token', token).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
	issuer: function(issuer) {
		return HTTP.get('auth/issuer/'+issuer).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
	verify: function(transaction) {
		return HTTP.post('auth/verify', transaction).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
}
