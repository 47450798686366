<template>
  <div class="portal">
		<template v-if="!loadingStates.app">
    	<app-header></app-header>
    	<router-view></router-view>
		</template>
  </div>
</template>

<script>
	import Vue from 'vue';
	import AppHeader from './layout/AppHeader.vue';
	import * as types from '../store/mutation-types';
  import {mapState} from 'vuex';

  export default {
		name: 'app',
		components: {
      AppHeader
    },
		computed: {
      ...mapState ('auth', [
				'isAuthenticated',
				'isConfirmed',
			]),
      ...mapState ('app', [
				'loadingStates',
				'locale',
				'portal',
				'updatedAt',
      ])
		},
		created() {
			// add new recaptcha script with locale in head of document
			var url = 'https://www.google.com/recaptcha/api.js?render=explicit&hl='+this.locale;
			var $el = document.createElement('script');
			
			$el.src = url;
			$el.id = 'recaptcha';
			$el.async = true;
			$el.defer = true;

			document.head.appendChild($el);

			// check if the current user has a valid session
			// the session cookie is set to httponly so, on each xhr call we update the app's timestamp
			// the proxy api sets/renew it session to on hour on activity, so we imitate that on the client side too
			if (this.isConfirmed && this.isAuthenticated) {
				let idleTime = Math.floor((Date.now() - this.updatedAt) / 1000);
				if (Math.floor((idleTime / 60) / 60) >= 1) {
					// idle time has been greater than one hour, so reset and redirect to login directly if not already
					this.$store.commit('app/'+types.RESET_APP);
					this.$store.commit('user/'+types.RESET_USER);
					this.$store.commit('auth/'+types.RESET_AUTH);
					this.$router.replace('/inloggen');
				}
			}			

			// init the app/portal settings
			this.$store.dispatch('app/init').then((response) => {
                this.$i18n.setLocaleMessage('nl', response.translations);
                this.$i18n.setLocaleMessage('en', response.translations);
                // nothings it works, moving on..
                // $('#errorOnLoad').hide();
            })

			// reset notice one refresh
			this.$store.commit('app/'+types.RESET_NOTICE);
		}
  }
</script>
