import {HTTP} from './http-common';

export default {
    fetch: function() {
		return HTTP.get('user').then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
    },
    fetchAddress(address) {
        address = address.replace(/\\|\//g,'');
		return HTTP.get('address/complete/'+ address).then((response) => {
			return response.data.data;
		}).catch((error) => {
			return Promise.reject(error.response.data);
		});
	},
    update: function(user) {
        return HTTP.patch('user', user).then((response) => {
			return response.data.data;
		}).catch((error) => {
            console.log(error);
			return Promise.reject(error);
		});
	}	
}
