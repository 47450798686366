import authService from '../../services/auth-service';
import * as types from '../mutation-types';

export default {
  namespaced: true,
  state: {
		// change firstlogin to verified, we dont need it @todo refactor this and in API
		isFirstLogin: false,
		isVerified: false,
		isAuthenticated: false,
        isConfirmed: null,
        portalLogin: false,
        medicalLogin: false,
		tokenSent: false,
        type: null,
        resetLastName: false,
        resetContact: false,
		availableTypes: {
			email: false,
            mobile: false,
            mobileMasked: null,
            lastname: null,
            lastnamePassport: null,
        },
    },
    mutations: {
		[types.SET_FIRST_LOGIN] (state, firstLogin) {
			state.isFirstLogin = firstLogin;
        },
        [types.SET_RESETLASTNAME] (state, resetLastName) {
            state.resetLastName = resetLastName;
        },
        [types.SET_RESETCONTACT] (state, resetContact) {
            state.resetContact = resetContact;
        },
		[types.RESET_CONFIRMED] (state) {
			state.isConfirmed = null;
		},
		[types.CONFIRMED] (state) {
			state.isConfirmed = true;
		},
		[types.CONFIRMED_TYPES] (state, types) {
			state.availableTypes.email = types.email;
            state.availableTypes.mobile = types.mobile;
            state.availableTypes.lastname = types.lastname;
            state.availableTypes.lastnamePassport = types.lastnamePassport;
		},
		[types.NOT_CONFIRMED] (state) {
			state.isConfirmed = false;
		},	
		[types.TOKEN_SENT] (state) {
			state.tokenSent = true;
		},
		[types.TOKEN_NOT_SENT] (state) {
			state.tokenSent = false;
		},
		[types.SET_TYPE] (state, type) {
			state.type = type;
		},
		[types.RESET_TYPES] (state, type) {
			state.type = null;
			state.availableTypes.email = false;
			state.availableTypes.mobile = false;
		},
		[types.AUTHENTICATED] (state) {
			state.isAuthenticated = true;
		},
		[types.NOT_AUTHENTICATED] (state) {
			state.isAuthenticated = false;
		},
		[types.VERIFIED] (state) {
			state.isVerified = true;
		},
		[types.NOT_VERIFIED] (state) {
			state.isVerified = false;
		},
		[types.RESET_AUTH] (state) {
			state.isVerified = false;
			state.isAuthenticated = false;
			state.isConfirmed = null,
			state.tokenSent = false,
			state.isFirstLogin = false,
            state.type = null,
            state.portalLogin = false,
            state.medicalLogin = false,
            state.resetLastName = false;
            state.resetContact = false;
			state.availableTypes = {
				email: false,
				mobile: false
			};
        },
        [types.SET_MEDICALLOGIN] (state, medicalLogin) {
			state.medicalLogin = medicalLogin;
        },
        [types.SET_PORTALLOGIN] (state, portalLogin) {
			state.portalLogin = portalLogin;
		},
    },
    actions: {
        confirm (state, user) {
            // save current state if needed
            // commit something, loading or reset if needed for example array etc
            state.commit(types.RESET_CONFIRMED);
            return authService.confirm(user).then((response) => {
                if (response.isConfirmed === true) {
                    state.commit(types.CONFIRMED);
                    state.commit(types.SET_FIRST_LOGIN, response.isFirstLogin);
                    state.commit(types.SET_RESETLASTNAME, response.resetLastName);
                    state.commit(types.SET_RESETCONTACT, response.resetContact);

                    state.commit(types.SET_MEDICALLOGIN, response.medicalLogin);
                    state.commit(types.SET_PORTALLOGIN, response.portalLogin);
                    state.commit(types.CONFIRMED_TYPES, {
                        email: response.email,
                        mobile: response.mobile,
                        lastname: response.lastname,
                        lastnamePassport: response.lastnamePassport,
                        
                    });
                    return Promise.resolve(response);
                }
                state.commit(types.NOT_CONFIRMED);
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        },
        login (state, type)	{
            // generate and sent one time password, OTP
            state.commit(types.TOKEN_NOT_SENT);
            return authService.login(type).then((response) => {
                if (response.tokenSent === true) {
                    state.commit(types.TOKEN_SENT);
                    state.commit(types.SET_TYPE, type.type);
                    return Promise.resolve(response);
                }
                state.commit(types.TOKEN_NOT_SENT);
                return Promise.resolve(response);
            }).catch((error) => {
                state.commit(types.TOKEN_NOT_SENT);
                return Promise.reject(error);
            });
        },
        logout (state)	{
            return authService.logout().then((response) => {
                if (response.logout === true) {
                    // @todo reset every property instead of clearing global for now refresh page
                    // state.commit(types.RESET_AUTH);
                    // state.commit('user/'+types.RESET_USER, null, {root: true});
                    // state.commit('app/'+types.RESET_APP, null, {root: true});
                    return Promise.resolve(response);
                }
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        },		
        authenticate (state, token) {
            // authenticate current user by token
            state.commit(types.NOT_AUTHENTICATED);
            return authService.authenticate(token).then((response) => {
                if (response.isAuthenticated === true) {
                    state.commit(types.AUTHENTICATED);
                    if (response.isVerified === true) {
                        state.commit(types.VERIFIED);
                    } else {
                        state.commit(types.NOT_VERIFIED);
                    }
                    return Promise.resolve(response);
                }
                state.commit(types.NOT_AUTHENTICATED);
                state.commit(types.NOT_VERIFIED);
                return Promise.resolve(response);
            }).catch((error) => {
                state.commit(types.NOT_AUTHENTICATED);
                state.commit(types.NOT_VERIFIED);
                return Promise.reject(error);
            });
        },
        issuer (state, issuer) {
            // authenticate with issuer
            return authService.issuer(issuer).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        },
        verify (state, transaction) {
            return authService.verify(transaction).then((response) => {
                if (response.isVerified === true) {
                    state.commit(types.VERIFIED);
                    return Promise.resolve(response);
                }
                state.commit(types.NOT_VERIFIED);
                return Promise.resolve(response);
            }).catch((error) => {
                state.commit(types.NOT_VERIFIED);
                return Promise.reject(error);
            });
        }
    }
}
