<template>
    <div v-if="!isVerified" class="col-md-9">
        <template>
            <login-idin></login-idin>
        </template>
        
    </div>    
	<div v-else class="col-md-9">
        <modal ref="modal">
            <template slot="modal-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal-content--side modal-content--side--left">
                            <h2 class="modal__title">{{ $t("popup.hlatitle") }}</h2>
                            <div v-html="$t('popup.hlatext')"></div>

                            <a v-on:click.prevent="modalClose" class="close-modal">
                                <span class="hidden">Venster sluiten</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <path fill="#FFF" fill-rule="evenodd" d="M26.609375,24.8554688 C26.7031255,24.9492192 26.75,25.0664055 26.75,25.2070312 C26.75,25.347657 26.7031255,25.4648433 26.609375,25.5585938 L25.2734375,26.859375 C25.179687,26.9531255 25.0742193,27 24.9570312,27 C24.8164055,27 24.6992192,26.9531255 24.6054688,26.859375 L20,22.2539062 L15.3945312,26.859375 C15.3007808,26.9531255 15.1953131,27 15.078125,27 C14.9374993,27 14.820313,26.9531255 14.7265625,26.859375 L13.390625,25.5585938 C13.2968745,25.4648433 13.25,25.347657 13.25,25.2070312 C13.25,25.0664055 13.2968745,24.9492192 13.390625,24.8554688 L18.03125,20.25 L13.390625,15.6796875 C13.1562488,15.4453113 13.1562488,15.2109387 13.390625,14.9765625 L14.6914062,13.640625 C14.832032,13.5468745 14.9492183,13.5 15.0429688,13.5 C15.1835945,13.5 15.3007808,13.5468745 15.3945312,13.640625 L20,18.2109375 L24.6054688,13.640625 C24.7460945,13.5468745 24.8632808,13.5 24.9570312,13.5 C25.097657,13.5 25.2148433,13.5468745 25.3085938,13.640625 L26.609375,14.9765625 C26.8437512,15.2109387 26.8437512,15.4335927 26.609375,15.6445312 L21.96875,20.2148438 L26.609375,24.8554688 Z" transform="translate(-13 -13)"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <portal-personaldetails-editpersonal></portal-personaldetails-editpersonal>

		<div class="row">
			<div class="col-md-6 padding-left-10">
				<div class="box">

					<template v-if="loadingStates.user">
						<portal-loader-circle></portal-loader-circle>
					</template>
					<template v-else>
						<div class="box-item">
							<strong class="box-item-title">{{ $t('label.registerdate') }}</strong>
							<p class="box-item-text">{{ user.registrationDate }}</p>
						</div>
					</template>

				</div>
			</div>

			<div class="col-md-6 padding-left-10">
				<div class="box">

					<template v-if="loadingStates.user">
						<portal-loader-circle></portal-loader-circle>
					</template>
					<template v-else>
						<div class="box-item">
							<strong class="box-item-title">{{ $t('label.bloodtype') }}</strong>
							<p class="box-item-text">{{ user.bloodType }}</p>
						</div>
					</template>

				</div>
			</div>

			<div class="col-md-12">
				<div class="box">

					<template v-if="loadingStates.user">
						<portal-loader-circle></portal-loader-circle>
					</template>
					<template v-else>
						<div class="row equalChildHeight">
							<div class="col-sm-8 border-right">
								<div class="box-item">
									<strong class="box-item-title">{{ $t('label.hlatype') }}</strong>
									<p class="box-item-text">
										<span v-for="hla in user.hla">
											{{ hla }}<br/>
										</span>
									</p>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="box-item">
									<strong class="box-item-title"><span class="fa fa-info-circle"></span> {{ $t('medical.hla') }}</strong>
									<p class="box-item-text">
                                        {{ $t('medical.hladesc') }}
                                        <br>
                                        <a href="javascript:;" v-on:click.prevent="modalOpen">{{ $t("button.hlamore")}}</a>
                                    </p>
								</div>
							</div>
						</div>
					</template>

				</div>
			</div>

			<div class="col-md-12">
				<div class="box">

					<template v-if="loadingStates.user">
						<portal-loader-circle></portal-loader-circle>
					</template>
					<template v-else>
						<div class="row equalChildHeight">
							<div class="col-sm-8 border-right">
								<div class="box-item">
									<strong class="box-item-title">{{ $t('label.cmvtype') }}</strong>
									<p class="box-item-text">{{ user.cmv }}</p>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="box-item">
									<strong class="box-item-title"><span class="fa fa-info-circle"></span> {{ $t('medical.cmv') }}</strong>
									<p class="box-item-text">{{ $t('medical.cmvdesc') }}</p>
								</div>
							</div>
						</div>
					</template>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Vue from 'vue';
    import {mapState} from 'vuex';
    import Modal from '../modal/Modal.vue';
    import LoginIdin from '../LoginIdin.vue';
    import PortalLoaderCircle from './layout/PortalLoaderCircle.vue';
    import PortalPersonaldetailsEditpersonal from './personal_details/PersonaldetailsEditpersonal.vue';

	export default {
		name: 'portal-medical-data',
		components: {
            PortalLoaderCircle,
            Modal,
            LoginIdin,
            PortalPersonaldetailsEditpersonal
        },
        methods: {
            modalOpen: function() {
                this.$refs.modal.open();
            },
            modalClose: function() {
                this.$refs.modal.close();
            },
        },

		computed: {
    	...mapState ('user', [
      	'user'
            ]),
        ...mapState ('auth', [
      	'isVerified'
			]),
    	...mapState ('app', [
      	'loadingStates'
			])
		}
	}
</script>
