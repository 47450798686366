<template>
	<div class="col-md-9">
		<div class="row">
			<!-- <portal-overview-availability></portal-overview-availability> -->
			<portal-overview-status></portal-overview-status>
			<div class="col-md-12">
				<div class="row equalChildHeight">
					<!-- <portal-overview-user></portal-overview-user> -->
				</div>
			</div>
		</div>
        <div class="row">
			<portal-personaldetails-editname></portal-personaldetails-editname>
			<div class="col-md-12">
				<div class="row">
					<portal-personaldetails-editcontact></portal-personaldetails-editcontact>
					<portal-overview-newsletter></portal-overview-newsletter>
					
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
	// import PortalOverviewAvailability from './overview/OverviewAvailability.vue';
	import PortalOverviewStatus from './overview/OverviewStatus.vue';
	import PortalOverviewUser from './overview/OverviewUser.vue';
	import PortalOverviewNewsletter from './overview/OverviewNewsletter.vue';
    import PortalPersonaldetailsEditname from './personal_details/PersonaldetailsEditname.vue';
	import PortalPersonaldetailsEditcontact from './personal_details/PersonaldetailsEditcontact.vue';
	import PortalPersonaldetailsEditpersonal from './personal_details/PersonaldetailsEditpersonal.vue';

	export default {
		name: 'portal-overview',
		components: {
			// PortalOverviewAvailability,
			PortalOverviewStatus,
			PortalOverviewUser,
            PortalOverviewNewsletter,
            PortalPersonaldetailsEditname,
			PortalPersonaldetailsEditcontact,
			PortalPersonaldetailsEditpersonal
		}
	}
</script>
