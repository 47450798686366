<template>
	<div class="col-md-3">
		<div class="asidenav">
			<ul>
				<li :class="{'active': $router.currentRoute._rawValue.path == '/'}"><router-link to="/" ><span class="fa fa-list"></span> {{ $t('portal.personal') }}</router-link></li>
				<!-- <router-link tag="li" to="/persoonlijk"><a><span class="fa fa-list"></span> {{ $t('portal.personal') }}</a></router-link> -->
				<li v-if="medicalLogin" :class="{'active': $router.currentRoute._rawValue.path == '/medisch'}"><router-link to="/medisch"><span class="fa fa-medkit"></span> {{ $t('portal.medical') }}</router-link></li>
			</ul>
		</div>
	</div>
</template>

<script>
    import {mapState} from 'vuex';
    
    export default {
        name: 'portal-navigation',
        computed: {
            ...mapState ('auth', [
                'medicalLogin',
            ]),
        }
	}
</script>
