import { createRouter, createWebHistory } from 'vue-router'
import VueRouter from 'vue-router';
import store from './store';
import * as types from './store/mutation-types';


// routes components
import Portal from './components/Portal.vue';
import PortalOverview from './components/portal/Overview.vue';
import PortalPersonaldetails from './components/portal/Personaldetails.vue';
import PortalMedicaldata from './components/portal/Medicaldata.vue';
import Login from './components/Login.vue';
import LoginAuthenticate from './components/LoginAuthenticate.vue';
import LoginIdin from './components/LoginIdin.vue';
import LoginIdinStatus from './components/LoginIdinStatus.vue';
import VerifyContact from './components/VerifyContact.vue';
import VerifyAddress from './components/VerifyAddress.vue';
import VerifyLastname from './components/VerifyLastname.vue';

let routes = [
    {
        path: '/',
        component: Portal,
        meta: {
            // requiresAuthLvl 3 = confirmed/authentication/verified 2 = confirmed/auth 1 = confirmed
            requiresAuthLvl: 2
        },
        children: [
            {
                path: '',
                name: 'portal-overview',
                component: PortalOverview
            },
            {
                path: 'persoonlijk',
                name: 'portal-personaldetails',
                component: PortalPersonaldetails
            },
            {
                path: 'medisch',
                name: 'portal-medicaldata',
                component: PortalMedicaldata,
                beforeEnter: (to, from, next) => {
                    // already authenticated redirect..
                    if (store.state.auth.isAuthenticated && store.state.auth.isConfirmed && store.state.auth.medicalLogin) {
                        next();
                    } else {
                        next({
                            path: '/'
                        });
                    }
                }
            }
        ]
    },
    {
        path: '/inloggen',
        name: 'login',
        component: Login,
        meta: {
            isLogin: true
        },
        beforeEnter: (to, from, next) => {
            // already authenticated redirect..
            if (store.state.auth.isAuthenticated && store.state.auth.isConfirmed) {
                next({
                    path: '/'
                });
            } else {
                next();
            }
        }
    },
    {
        path: '/inloggen/authenticatie',
        name: 'login-authenticate',
        component: LoginAuthenticate,
        props: true,
        meta: {
            requiresAuthLvl: 1,
            isLogin: true
        },
        beforeEnter: (to, from, next) => {
            // already authenticated redirect..
            if (store.state.auth.isAuthenticated && store.state.auth.isConfirmed) {
                next({
                    path: '/'
                });
            } else {
                next();
            }
        }
    },
    {
        path: '/inloggen/idin/status',
        name: 'login-idin-status',
        component: LoginIdinStatus,
        meta: {
            requiresAuthLvl: 2,
            isLogin: true
        },
        beforeEnter: (to, from, next) => {
            // already authenticated redirect..
            if (store.state.auth.isVerified && store.state.auth.isAuthenticated && store.state.auth.isConfirmed && !store.state.auth.isFirstLogin) {
                next({
                    path: '/'
                });
            } else {
                next();
            }
        }
    },
    {
        path: '/controleren/contact',
        name: 'verify-contact',
        component: VerifyContact,
        meta: {
            requiresAuthLvl: 2,
            isLogin: true
        },
        beforeEnter: (to, from, next) => {
            // already authenticated redirect..
            console.log(store.state);
            if (store.state.auth.isAuthenticated && store.state.auth.isConfirmed && (store.state.auth.availableTypes.email && store.state.auth.availableTypes.mobile)) {
                next({
                    path: '/'
                });
            } else {
                next();
            }
        }
    },
    {
        path: '/controleren/adres',
        name: 'verify-address',
        component: VerifyAddress,
        meta: {
            requiresAuthLvl: 2,
            isLogin: true
        },
        beforeEnter: (to, from, next) => {
            // already authenticated redirect..
            if (store.state.auth.isAuthenticated && store.state.auth.isConfirmed && !store.state.auth.resetContact) {
                next({
                    path: '/'
                });
            } else {
                store.dispatch('user/fetch').then((response) => {
                    next();
                });
            }
        }
    },
    {
		path: '/controleren/achternaam',
		name: 'verify-lastname',
		component: VerifyLastname,
		meta: {
			requiresAuthLvl: 2,
			isLogin: true
		},
		beforeEnter: (to, from, next) => {
			// already authenticated redirect..
			if (store.state.auth.isAuthenticated && store.state.auth.isConfirmed && !store.state.auth.resetLastName) {
				next({path: '/'});
			} else {
				store.dispatch('user/fetch').then((response) => {
                    next();
                });
			}
		}
	},
    {
        path: '/*',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
  })
  

// add guards to routes
router.beforeEach((to, from, next) => {
    // check if localisation/init query isset if so set new locale, will fill if api doens't recognise
    if (to.query.locale) {
        store.commit('app/' + types.SET_LOCALE, to.query.locale);
    }

    // not verified
    if (to.matched.some((route) => route.meta.requiresAuthLvl == 3)) {
        if (!store.state.auth.isVerified && !store.state.auth.isAuthenticated && !store.state.auth.isConfirmed) {
            next({
                path: '/inloggen'
            });
        } else {
            next();
        }
        // not authenticated
    } else if (to.matched.some((route) => route.meta.requiresAuthLvl == 2)) {
        if (!store.state.auth.isAuthenticated && !store.state.auth.isConfirmed) {
            next({
                path: '/inloggen'
            });
        } else {
            next();
        }
        // not confirmed
    } else if (to.matched.some((route) => route.meta.requiresAuthLvl == 1)) {
        if (!store.state.auth.isConfirmed) {
            next({
                path: '/inloggen'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
