// Es6 promise IE11 fix
import Es6Promise from 'es6-promise';
Es6Promise.polyfill();

import Vuex from 'vuex';
import app from './modules/app';
import user from './modules/user';
import auth from './modules/auth';
import createPersistedState from 'vuex-persistedstate'
import _ from 'lodash';
const debug = process.env.VUE_APP_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
		app,
    user,
    auth
  },
	strict: debug,
	plugins: [
		createPersistedState({
			key: 'store',
			paths: ['auth', 'app', 'i18n']
		})
	]
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

// reset state if needed
export function resetState () {
	store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
